import Api from '@dydu_ai/dydu-api';
import { DyduStorage } from './Constants';
import axios, { AxiosResponse } from 'axios';

export const casUrl = window?.DYDU_TARGETS?.cas_url;
export const appRoot = window?.DYDU_TARGETS?.app_root;
const url_callbot = window?.DYDU_TARGETS?.callbot_url;
// base64 of {"appWelcomeSentence":"cas.login.welcome.channels"}
export const casLoginMessage = 'eyJhcHBXZWxjb21lU2VudGVuY2UiOiJjYXMubG9naW4ud2VsY29tZS5jaGFubmVscyJ9';
export const casClientName = 'ChannelsSSOClient';
export const encodedServiceUrl = encodeURIComponent(
  window.location.origin +
    (window.location.pathname && window.location.pathname !== '/' ? window.location.pathname.replace(/\/+$/, '') : '') +
    '/?client_name=' +
    casClientName
);
const host = sessionStorage.getItem(DyduStorage.HOST);

const getGatewayUrl = () => {
  if (sessionStorage.getItem(DyduStorage.PROXY)) {
    return sessionStorage.getItem(DyduStorage.PROXY);
  }
  return window?.DYDU_TARGETS?.gateway_url;
};

export function getClientApi() {
  const headers = {};
  // TODO: find a way to rely on casClient.getToken() to do not read token in sessionStorage (casClient may store it anywhere else)
  const token = sessionStorage.getItem(DyduStorage.TOKEN) || '';
  return Api.getInstance(getGatewayUrl(), token, headers);
}

export const getCallBotConfigurations = async (atriaServer: string, botUUID: string) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  try {
    const res: AxiosResponse = await axios.get(url_callbot + `/instances/${atriaServer}/${botUUID}`, { headers });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const updateCallBotVoice = async (instanceUUID: string, data: object) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  try {
    const res: AxiosResponse = await axios.post(url_callbot + `/instances/${instanceUUID}`, data, { headers });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllCallBotVoicesAvailables = async (instanceUUID: string) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  try {
    const res: AxiosResponse = await axios.get(url_callbot + `/tts/voices/${instanceUUID}`, { headers });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const generateTts = async (
  instanceUUID: string,
  voice: string,
  content: string,
  provider: string,
  language: string
) => {
  const headers = {
    accept: 'application/octet-stream',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  const params = {
    provider: provider,
    language: language,
    voice: voice,
    content: content,
  };
  const responseType = 'blob';
  try {
    const res: AxiosResponse = await axios.post(url_callbot + `/tts/generate/${instanceUUID}`, '', {
      headers,
      params,
      responseType,
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const createRagConfig = async (data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(getGatewayUrl() + '/api/dydubox_backend/rag', data, {
    headers,
  });
  return res.data;
};

export const getRagConfigurationsForBotUuid = async (botUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.get(getGatewayUrl() + `/api/dydubox_backend/rag/bot/${botUUID}`, {
    headers,
  });
  return res.data;
};

export const deleteRagConfiguration = async (ragUUID: string | undefined, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.delete(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}`, {
    headers,
  });
  return res.data;
};

export const deleteRagConfigurationSource = async (
  ragUUID: string | undefined,
  sourceUUID: string | undefined,
  token: string
) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.delete(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/${sourceUUID}`, {
    headers,
  });
  return res.data;
};

export const addRagConfigurationSourceFile = async (
  ragUUID: string | undefined,
  data: string,
  token: string,
  onProgress: (progress: number) => void
) => {
  const headers = {
    accept: '*/*',
    'content-type': 'multipart/form-data',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const formData = new FormData();
  formData.append('file', data);
  const config = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress && onProgress(percentCompleted);
    },
    headers,
  };
  const res: AxiosResponse = await axios.post(
    getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/file`,
    formData,
    config
  );
  return res.data;
};

export const addRagConfigurationSharePoint = async (ragUUID: string | undefined, data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(
    getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/sharepoint`,
    data,
    {
      headers,
    }
  );
  return res.data;
};

export const updateRagConfigurationSharepoint = async (ragUUID: string | undefined, data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.put(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/sharepoint`, data, {
    headers,
  });
  return res.data;
};

export const addRagConfigurationWebSite = async (ragUUID: string | undefined, data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/website`, data, {
    headers,
  });
  return res.data;
};

export const updateRagConfigurationWebsite = async (ragUUID: string | undefined, data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.put(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/website`, data, {
    headers,
  });
  return res.data;
};

export const downloadRagConfigurationFile = async (
  ragUUID: string | undefined,
  fileUUID: string | undefined,
  token: string
) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.get(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/file/${fileUUID}`, {
    headers,
    responseType: 'blob',
  });
  return res.data;
};

export const indexRagConfiguration = async (ragUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(
    getGatewayUrl() + `/api/dydubox_backend/rag/index/${ragUUID}`,
    {},
    { headers }
  );
  return res.data;
};

export const getRagStatus = async (ragUUID: string, source: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const params = {
    source,
  };
  const res: AxiosResponse = await axios.get(getGatewayUrl() + `/api/dydubox_backend/rag/${ragUUID}/status`, {
    headers,
    params,
  });
  return res.data;
};

export const generateExcel = async (ragUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer ' + token,
  };
  const res: AxiosResponse = await axios.post(
    getGatewayUrl() + `/api/dydubox_backend/rag/excel/${ragUUID}/generate`,
    {
      chunkSize: 512,
      language: 'French',
      themeList: [
        {
          theme: 'string',
          subThemes: ['string'],
        },
      ],
    },
    { headers }
  );
  return res.data;
};

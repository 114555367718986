import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getBaseUrl } from 'src/Tools/Url';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LocalStorage from 'src/Tools/LocalStorage';

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    cleanCode: true,
    debug: false,
    detection: {
      lookupCookie: 'i18next',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      lookupLocalStorage: LocalStorage.names.locale,
      lookupQuerystring: 'lng',
      lookupSessionStorage: 'persist:DYDU',
      order: ['localStorage', 'sessionStorage', 'navigator', 'querystring', 'path', 'htmlTag', 'subdomain'],
    },
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    load: 'languageOnly',
    lowerCaseLng: true,
    react: { useSuspense: false },
    returnObjects: true,
    supportedLngs: ['fr', 'en'],
    backend: {
      loadPath: `${getBaseUrl()}/locales/{{lng}}/translation.json`,
      requestOptions: {
        cache: 'no-store',
      },
    },
  });

export default i18next;

import { Control, ValidationRule, useController } from 'react-hook-form';
import { InputContent, InputRadio, InputText, InputTextarea, InputWrapper, Label, Title } from './Style';
import { useEffect, useState } from 'react';

import Icon from 'src/Components/Icon/Icon';
import { InputProps } from './Input';
import { truncateString } from 'src/Tools/Text';
import { useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';

interface ControlledInputProps extends InputProps {
  control: Control<any>;
  name: string;
  defaultValue?: any;
  rules?: {
    required?: boolean;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    pattern?: ValidationRule<RegExp>;
  };
  delay?: number;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  triggerFocus?: boolean;
  hidden?: boolean;
  icon?: any;
  id?: string;
  wide?: boolean;
  inputId?: string;
  value?: string;
  iconPos?: 'left' | 'right';
  className?: string;
  title?: string;
}

const ControlledInput = ({
  control,
  name,
  label,
  defaultValue = '',
  rules,
  hidden,
  wide,
  delay = 100,
  placeholder,
  className = '',
  required,
  autoFocus,
  icon,
  iconPos = 'right',
  type = 'text',
  title,
  ...props
}: ControlledInputProps) => {
  const { t } = useTranslation();

  const {
    field: { onChange, ...fields },
    fieldState,
  } = useController({
    name,
    control,
    rules: {
      required,
      ...rules,
    },
    defaultValue,
  });

  const [fieldValue, setFieldValue] = useState<string>('');

  const remainingChar = rules?.maxLength && rules?.maxLength - fieldValue?.length;

  useEffect(() => {
    setFieldValue(fields.value);
  }, [fields.value]);

  useDebounce(
    () => {
      onChange(fieldValue);
    },
    delay,
    [fieldValue]
  );

  const checkValue = (value) => {
    if (rules?.maxLength) {
      return truncateString(value, rules?.maxLength);
    }
    return value;
  };

  const change = (e) => {
    if (remainingChar === 0 && !['deleteContentBackward', 'deleteByCut'].includes(e.nativeEvent.inputType)) return;
    const checkedValue = checkValue(e.target.value);
    setFieldValue(checkedValue);
  };

  const inputProps = {
    ...fields,
    ...props,
    onChange: change,
    value: fieldValue || '',
    placeholder,
    required,
    autoFocus,
    className: `${className} ${fieldState?.error ? 'p-invalid' : ''}`,
  };

  const renderInputType = () => {
    switch (type) {
      case 'text':
        return <InputText {...inputProps} />;
      case 'textarea':
        return <InputTextarea {...inputProps} />;
      case 'radio':
        return <InputRadio {...inputProps} />;
      default:
        return <InputText {...inputProps} />;
    }
  };

  const renderLabelOrTitle = () => {
    if (title) {
      return <Title className='title'>{title}</Title>;
    } else if (label) {
      return (
        <Label className='label' type={fieldState?.error && 'error'}>
          {label}
        </Label>
      );
    }
  };

  const renderCharCounter = () =>
    rules?.maxLength && (
      <Label className='label' type={fieldState?.error && 'error'}>
        {t('general.form.remainingChar', { number: remainingChar })}
      </Label>
    );

  const renderContent = () => (
    <InputContent className={`p-float-label p-input-icon-${iconPos}`} direction={title ? 'row' : 'column'} wide={wide}>
      {icon && <Icon className='input-icon' icon={icon} size={12} />}
      {renderInputType()}
      {renderCharCounter()}
    </InputContent>
  );

  const renderError = () => fieldState?.error && <small className='p-error block'>{fieldState?.error?.message}</small>;

  return (
    <InputWrapper
      className={`controlled-input ${className}`}
      hidden={hidden}
      wide={wide}
      direction={title ? 'row' : 'column'}
    >
      {renderLabelOrTitle()}
      {renderContent()}
      {renderError()}
    </InputWrapper>
  );
};

export default ControlledInput;

import { ColumnWrapper, InnerContent } from 'src/Pages/Edition/Style';

import ControlledInput from 'src/Components/Input/ControlledInput';
import ControlledSwitch from 'src/Components/Switch/ControlledSwitch';
import { DisplayProps } from '../Display';
import Message from 'src/Components/Message/Message';
import { useTranslation } from 'react-i18next';

interface AdditonalTabsProps extends DisplayProps {}

const AdditionalTabs = ({ watch, control }: AdditonalTabsProps) => {
  const { t } = useTranslation();

  const renderBannerPanel = () =>
    watch('content.banner.active') && (
      <div className='options-panel'>
        <ControlledSwitch
          icon='open-in-new'
          control={control}
          label={t('edition.display.field.linkButton')}
          name='content.banner.more'
        />
        {watch('content.banner.more') && (
          <ControlledInput
            control={control}
            iconPos='left'
            icon='link'
            name='content.banner.moreLink'
            rules={{
              pattern: {
                value: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm,
                message: t('form.url.error'),
              },
            }}
          />
        )}
        <ControlledSwitch
          icon='close'
          control={control}
          className='close-button'
          label={t('edition.display.field.closeButton')}
          name='content.banner.dismissable'
        />
        <Message severity='info' wide text={t('edition.display.field.contactTabInfo')} />
      </div>
    );

  return (
    <ColumnWrapper className='banner-panel'>
      <InnerContent>
        <ControlledSwitch
          control={control}
          label={t('edition.display.field.contactTab')}
          icon='contact-book'
          name='content.tabs.hasContactTab'
        />
        {watch('content.tabs.hasContactTab') && (
          <Message severity='info' wide text={t('edition.display.field.contactTabInfo')} />
        )}
      </InnerContent>
      <InnerContent>
        <ControlledSwitch
          control={control}
          label={t('edition.display.field.infoBanner')}
          icon='bookmark'
          name='content.banner.active'
        />
        {renderBannerPanel()}
      </InnerContent>
    </ColumnWrapper>
  );
};

export default AdditionalTabs;

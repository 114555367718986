import { APP_PATH } from 'src/Tools/Constants';
import { BreadCrumb } from './Style';
import { BreadcrumbContainerProps } from './BreadcrumbContainer';
import Icon from '../Icon/Icon';
import { color_grey__500 } from 'src/Assets/Styles/Abstracts/variables';
import { useTranslation } from 'react-i18next';
import { useCallBotConfigInfo } from '../../Contexts/CallBotContext';
import { useRagConfig } from '../../Contexts/RagConfigContext';

const Breadcrumb = ({ redirectToHome, selectedConfig, pathname }: BreadcrumbContainerProps) => {
  const { t } = useTranslation();
  const { selectedCallBotConfig, setSelectedCallBotConfig } = useCallBotConfigInfo();
  const { selectedRagConfiguration, setSelectedRagConfiguration } = useRagConfig();

  const renderBasePath = () => {
    switch (pathname) {
      case APP_PATH.NEW_CONFIG:
        return t('general.new_config');
      case APP_PATH.CONFIG_RAG:
        return t('general.ragEdition');
      case APP_PATH.CONFIG_RAG + '/' + selectedRagConfiguration?.id:
        return t('general.ragEdition');
      default:
        return t('general.configuration_list');
    }
  };

  const renderContent = () => {
    if (window.location.href?.includes(APP_PATH.CONFIG_RAG)) {
      return t('general.pages.rag');
    } else {
      return t('general.pages.main');
    }
  };

  const renderHome = () => (
    <div
      className='path-item'
      onClick={() => {
        setSelectedCallBotConfig(null);
        setSelectedRagConfiguration && setSelectedRagConfiguration(null);
        redirectToHome();
      }}
    >
      <div className='content'>
        <img src='Images/dydu-logo-min.svg' width='15' height='15' alt='Dydu' />
        {renderContent()}
      </div>
      <Icon className='caret' color={color_grey__500} icon='caret-right' size={10} />
    </div>
  );

  const renderConfig = (config) => (
    <>
      <Icon className='caret' color={color_grey__500} icon='caret-right' size={10} />
      <div className='content active'>{config.name || ''}</div>
    </>
  );

  const renderPath = () => (
    <div className='path-item'>
      <>
        <div
          className='content'
          onClick={() => {
            setSelectedCallBotConfig(null);
            setSelectedRagConfiguration && setSelectedRagConfiguration(null);
            redirectToHome();
          }}
        >
          {renderBasePath()}
        </div>
      </>
      {selectedConfig && renderConfig(selectedConfig)}
      {selectedCallBotConfig && renderConfig(selectedCallBotConfig)}
      {selectedRagConfiguration && renderConfig(selectedRagConfiguration)}
    </div>
  );

  return (
    <BreadCrumb>
      {renderHome()}
      {renderPath()}
    </BreadCrumb>
  );
};

export default Breadcrumb;

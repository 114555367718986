import { MainInputWrapper, MainLabel } from 'src/Components/Style';
import styled, { css } from 'styled-components';

import { Checkbox as PrimeCheckbox } from 'primereact/checkbox';
import { RadioButton as PrimeInputRadio } from 'primereact/radiobutton';
import { InputText as PrimeInputText } from 'primereact/inputtext';
import { InputTextarea as PrimeInputTextarea } from 'primereact/inputtextarea';

interface InputWrapperProps {
  hidden?: boolean;
  wide?: boolean;
  direction?: string;
}

export const InputWrapper = styled(MainInputWrapper)`
  ${({ direction }: InputWrapperProps) =>
    direction
      ? css`
          flex-direction: ${direction};
        `
      : css`
          flex-direction: column;
        `}

  ${({ direction }: InputWrapperProps) =>
    direction === 'row' &&
    css`
      align-items: center;
    `}

  ${({ hidden }: InputWrapperProps) =>
    hidden &&
    css`
      display: none;
    `}

  ${({ wide }: InputWrapperProps) =>
    wide &&
    css`
      width: 100%;
    `}

    &.checkbox {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

    .p-checkbox {
      margin-right: 10px;
    }
  }
`;

export const Title = styled.div`
  flex-grow: 0;
  align-items: center;
  font-size: 14px;
  padding: 0 40px 0 0;
  width: 170px;
`;

export const Label = styled(MainLabel)`
  font-size: 12px;
  ${({ type }: { type?: string }) =>
    type === 'error' &&
    css`
      color: ${({ theme }) => theme.color_critical};
    `}
`;

export const InputContent = styled.div`
  ${({ direction, wide }: InputWrapperProps) =>
    direction === 'row' &&
    wide &&
    css`
      flex-grow: 1;
    `}

  position: relative;

  .input-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: 20px;
    display: flex;
    vertical-align: middle;
    align-items: center;
  }
`;

export const InputText = styled(PrimeInputText)`
  border: 1px solid #c3c8ce;
  height: 40px;
  font-size: 14px;
`;

export const InputTextarea = styled(PrimeInputTextarea)`
  border: 1px solid #c3c8ce;
  min-height: 80px;
  font-size: 14px;
`;

export const InputRadio = styled(PrimeInputRadio)``;

export const InputCheckbox = styled(PrimeCheckbox)``;

import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { HeaderWrapper } from '../../../../../../ConfigurationsRag/Style';
import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { formatBoolean, MeterGroupValue } from './ModalErrorStatusInformation';
import { TreeTable } from 'primereact/treetable';
import { TreeNode } from 'primereact/treenode';
import { MultilineColumnData } from './Style';
import { TabPanel, TabView } from 'primereact/tabview';
import { MeterGroup } from 'primereact/metergroup';

interface ModalErrorStatusInformationSharepointProps {
  visible: boolean;
  onHide: () => void;
  sourceStatus?: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusSharepointDTO;
}

const ModalErrorStatusInformationSharepoint = ({
  visible,
  onHide,
  sourceStatus,
}: ModalErrorStatusInformationSharepointProps) => {
  const { t } = useTranslation();
  const [selectedSiteId, setSelectedSiteId] = useState<any>(null);
  const [selectedSite, setSelectedSite] = useState<
    API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusSharepointSiteDTO | null | undefined
  >(null);

  const [activeTab, setActiveTab] = useState<number>(0);

  const isPagesTabAvailable = (
    selectedSite: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusSharepointSiteDTO
  ) => {
    return selectedSite.pages && selectedSite.pages?.notAccessible && selectedSite?.pages?.notAccessible?.length > 0;
  };

  const isDrivesTabAvailable = (
    selectedSite: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusSharepointSiteDTO
  ) => {
    return selectedSite.drives && selectedSite.drives.length > 0;
  };

  const getInitialActiveTabValue = (
    selectedSite: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusSharepointSiteDTO | null | undefined
  ) => {
    if (selectedSite) {
      if (isPagesTabAvailable(selectedSite)) {
        return 0;
      } else if (isDrivesTabAvailable(selectedSite)) {
        return 1;
      }
    }
    return 0;
  };

  useEffect(() => {
    setSelectedSiteId({ name: sourceStatus?.sites && sourceStatus?.sites[0].siteId });
  }, [sourceStatus]);

  useEffect(() => {
    if (selectedSiteId) {
      const newSelectedSite = sourceStatus?.sites?.filter((s) => s.siteId === selectedSiteId.name)[0] || null;
      setSelectedSite(newSelectedSite);
      setActiveTab(getInitialActiveTabValue(newSelectedSite));
    }
  }, [selectedSiteId]);

  const renderHeaderModal = (
    <HeaderWrapper>
      <h1 className='p-panel-title'>{t('config_rag.general.status.sources.report.modal.title')}</h1>
    </HeaderWrapper>
  );

  const getSiteIds = (
    sourceStatus: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusSharepointDTO | undefined
  ) => {
    return sourceStatus?.sites?.map((s) => {
      return {
        name: s.siteId,
      };
    });
  };

  const formatTreeTableDrivesData = (
    sharepointSiteDrives: API_BACKEND.Schemas.RagContextInfoSharepointDriveFolderSourceStatus[] | undefined
  ): TreeNode[] => {
    const treeNodes: TreeNode[] = [];

    if (sharepointSiteDrives) {
      for (const sharepointSiteDirectory of sharepointSiteDrives) {
        treeNodes.push({
          id: sharepointSiteDirectory.name,
          key: sharepointSiteDirectory.name,
          data: {
            name: sharepointSiteDirectory.name,
            canBeRetrieved: sharepointSiteDirectory.canBeRetrieved,
            foldersNotRetrieved: sharepointSiteDirectory.foldersNotRetrieved,
            filesNotRetrieved: sharepointSiteDirectory.filesNotRetrieved,
          },
          children: formatTreeTableDrivesData(sharepointSiteDirectory.children),
        });
      }
    }

    return treeNodes;
  };

  const renderGlobalStatus = (
    sharepointSite: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusSharepointSiteDTO | undefined
  ) => {
    if (sharepointSite) {
      return (
        <DataTable
          value={[sharepointSite]}
          paginator
          rows={5}
          alwaysShowPaginator={false}
          className={'mt-5 mb-5'}
          showGridlines={true}
        >
          <Column
            field='isTokenRetrieved'
            header={t('config_rag.general.status.sources.report.modal.sharepoint.isTokenRetrieved')}
            align={'center'}
            alignHeader={'center'}
            body={(value) => formatBoolean(value.isTokenRetrieved)}
          ></Column>
          <Column
            field='pages.canBeRetrieved'
            header={t('config_rag.general.status.sources.report.modal.sharepoint.pages.canBeRetrieved')}
            align={'center'}
            alignHeader={'center'}
            body={(value) => formatBoolean(value?.pages?.canBeRetrieved)}
          ></Column>
          <Column
            field='areDrivesRetrieved'
            header={t('config_rag.general.status.sources.report.modal.sharepoint.drives.canBeRetrieved')}
            align={'center'}
            alignHeader={'center'}
            body={(value) => formatBoolean(value.areDrivesRetrieved)}
          ></Column>
        </DataTable>
      );
    }

    return null;
  };

  const renderPagesStatus = (
    sharepointSitePages: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusSharepointSitePagesDTO | undefined
  ) => {
    if (sharepointSitePages?.notAccessible && sharepointSitePages?.notAccessible?.length > 0) {
      return (
        <>
          {sharepointSitePages.accessible !== undefined && sharepointSitePages.accessible !== null ? (
            <MeterGroup
              values={buildMeterGroupFiles(
                sharepointSitePages,
                t('config_rag.general.status.sources.report.modal.sharepoint.pages.accessible'),
                t('config_rag.general.status.sources.report.modal.sharepoint.pages.notAccessible')
              )}
              max={sharepointSitePages.accessible + sharepointSitePages.notAccessible.length}
              className={'mb-5'}
              pt={{
                labellist: {
                  style: {
                    marginTop: '5px',
                  },
                },
              }}
            />
          ) : null}
          <DataTable
            value={sharepointSitePages.notAccessible.map((na) => {
              return { url: na };
            })}
            scrollable
            scrollHeight='flex'
          >
            <Column
              field='url'
              header={t('config_rag.general.status.sources.report.modal.sharepoint.pages.notAccessible')}
            />
          </DataTable>
        </>
      );
    }
  };

  const renderDrivesStatus = (
    sharepointSiteDrives: API_BACKEND.Schemas.RagContextInfoSharepointDriveFolderSourceStatus[] | undefined
  ) => {
    if (sharepointSiteDrives) {
      const count = { accessible: 0, notAccessible: 0 };
      for (const drive of sharepointSiteDrives) {
        countDrivesAccessibles(drive, count);
      }

      return (
        <>
          {count.accessible !== undefined && count.accessible !== null ? (
            <MeterGroup
              values={buildMeterGroupForAccessibleFiles(
                count.accessible,
                count.notAccessible,
                t('config_rag.general.status.sources.report.modal.sharepoint.drives.accessible'),
                t('config_rag.general.status.sources.report.modal.sharepoint.drives.notAccessible')
              )}
              max={count.accessible + count.notAccessible}
              className={'mb-5'}
              pt={{
                labellist: {
                  style: {
                    marginTop: '5px',
                  },
                },
              }}
            />
          ) : null}
          <TreeTable value={formatTreeTableDrivesData(sharepointSiteDrives)} scrollable scrollHeight='flex'>
            <Column field='name' header='Name' expander={true} style={{ width: '30%' }} />
            <Column
              field='canBeRetrieved'
              header={t('config_rag.general.status.sources.report.modal.sharepoint.drives.canBeRetrieved')}
              body={(value) => formatBoolean(value.data.canBeRetrieved)}
              style={{ width: '10%' }}
            />
            {sharepointSiteDrives.some((drive) => drive.foldersNotRetrieved?.length > 0) ? (
              <Column
                field='foldersNotRetrieved'
                header={t('config_rag.general.status.sources.report.modal.sharepoint.drives.foldersNotRetrieved')}
                body={(value) => {
                  return (
                    <MultilineColumnData>
                      {value.data.foldersNotRetrieved.map((folder: string) => (
                        <span>{folder}</span>
                      ))}
                    </MultilineColumnData>
                  );
                }}
                style={{ width: '30%' }}
              />
            ) : null}
            {sharepointSiteDrives.some((drive) => drive.filesNotRetrieved?.length > 0) ? (
              <Column
                field='filesNotRetrieved'
                header={t('config_rag.general.status.sources.report.modal.sharepoint.drives.notAccessible')}
                body={(value) => {
                  return (
                    <MultilineColumnData>
                      {value.data.filesNotRetrieved.map((file: string) => (
                        <span className={'mb-2'}>{file}</span>
                      ))}
                    </MultilineColumnData>
                  );
                }}
                style={{ width: '30%' }}
              />
            ) : null}
          </TreeTable>
        </>
      );
    }

    return null;
  };

  const buildMeterGroupFiles = (
    pages: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusSharepointSitePagesDTO,
    labelSuccess: string,
    labelError: string
  ) => {
    return buildMeterGroupForAccessibleFiles(pages.accessible, pages.notAccessible?.length, labelSuccess, labelError);
  };

  const countDrivesAccessibles = (
    drive: API_BACKEND.Schemas.RagContextInfoSharepointDriveFolderSourceStatus,
    count: { accessible: number; notAccessible: number }
  ) => {
    count.accessible += drive.filesRetrieved;
    count.notAccessible += drive.filesNotRetrieved.length;
    if (drive.children) {
      for (const child of drive.children) {
        countDrivesAccessibles(child, count);
      }
    }
  };

  const buildMeterGroupForAccessibleFiles = (
    countAccessible: number,
    countNotAccessible: number,
    labelSuccess: string,
    labelError: string
  ) => {
    const groups: MeterGroupValue[] = [];
    groups.push({
      label: labelSuccess,
      color: '#66b366',
      value: countAccessible,
    });
    groups.push({
      label: labelError,
      color: '#ff3800',
      value: countNotAccessible,
    });

    return groups;
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      dismissableMask={true}
      blockScroll={true}
      header={renderHeaderModal}
      style={{
        width: '80%',
        height: '80%',
      }}
      maximizable
    >
      <p>{t('config_rag.general.status.sources.report.modal.sharepoint.siteIdDropdownLabel')}</p>
      <Dropdown
        onChange={(e) => {
          if (e.value) {
            setSelectedSiteId(e.value);
          }
        }}
        options={getSiteIds(sourceStatus)}
        optionLabel='name'
        value={selectedSiteId}
        className='w-full md:w-14rem'
      />
      {selectedSite ? (
        <>
          {renderGlobalStatus(selectedSite)}
          <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
            {isPagesTabAvailable(selectedSite) ? (
              <TabPanel header={t('config_rag.general.status.sources.report.modal.sharepoint.pages.tab')}>
                {renderPagesStatus(selectedSite.pages)}
              </TabPanel>
            ) : null}
            {isDrivesTabAvailable(selectedSite) ? (
              <TabPanel header={t('config_rag.general.status.sources.report.modal.sharepoint.drives.tab')}>
                {renderDrivesStatus(selectedSite.drives)}
              </TabPanel>
            ) : null}
          </TabView>
        </>
      ) : null}
    </Dialog>
  );
};

export default ModalErrorStatusInformationSharepoint;

import styled from 'styled-components';

export const TableIcon = styled.img`
  width: 30px;
  margin: 0 10px;
`;

export const TypeListContainer = styled.div`
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #5060704d;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 40px;
  margin-top: 15px;

  /** TABLE WRAPPER */
  .p-datatable-wrapper {
    overflow: visible !important;
  }

  /** TABLE HEADER */
  .p-datatable-header {
    background: none;
    border: none;
  }

  /** TABLE */
  th {
    background: ${({ theme }) => theme.color_white}!important;
    color: ${({ theme }) => theme.color_grey__600} !important;
    font-weight: 500 !important;
    font-size: 14px;
  }

  tbody {
    tr {
      position: relative;
      &:hover {
        background-color: ${({ theme }) => theme.background_main_blue};
        cursor: pointer;
      }
      td {
        font-size: 14px;
      }
      &:last-child {
        border: none;
        td {
          border: none;
        }
      }
      &.disabled {
        cursor: initial;
        &:hover {
          background: ${({ theme }) => theme.color_white};
        }
        td {
          position: relative;
          &:after {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: ${({ theme }) => theme.color_white};
            opacity: 0.5;
          }
        }
      }
    }
  }

  .p-paginator {
    border-bottom: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: ${({ direction }: { direction: 'row' | 'column' }) => direction || 'row'};
  justify-content: space-between;
  align-items: center;
  h3 {
    color: ${({ theme }) => theme.color_grey__600};
    font-size: 21px;
    font-weight: medium;
  }
  h4 {
    color: ${({ theme }) => theme.color_grey__500};
    font-size: 14px !important;
    font-weight: normal;
  }
`;

export const StatusWrapper = styled.div`
  position: relative;
  &:hover .hover-component-wrapper {
    background: white;
    display: block;
    z-index: 5;
    border-radius: 6px;
    @media screen and (max-width: 460px) {
      display: none;
    }
  }
  .p-tag {
    margin: 0 3px 3px 0;
  }
`;

export const EmptyListContainer = styled.div`
  .heading {
    margin: 0;
  }

  h3 {
    color: ${({ theme }) => theme.color_grey__600};
    font-size: 48px;
    font-weight: medium;
    margin-top: 100px !important;
    margin-bottom: 20px !important;
  }

  h4 {
    color: ${({ theme }) => theme.color_grey__500};
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 40px !important;
  }

  .options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;

    > div {
      margin: 0;
    }
  }
`;

interface OptionContainerProps {
  createdConfigs?: any[];
}

export const OptionContainer = styled.div<OptionContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.createdConfigs && props.createdConfigs?.length > 0 ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  gap: 15px;
  > * {
    margin: 0px 0px 25px 0px !important;
    width: 100%;
  }
`;

export const PanelActionWraper = styled.div`
  width: 437px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color_grey__400};
  background: ${({ theme }) => theme.color_white};
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: all ease 0.5s;
  margin: 0 auto 80px auto;

  &:hover {
    box-shadow: 0px 2px 4px #5060704d;
    border-color: #7091d8;
  }

  .inner-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: inherit;

    .title {
      font-size: 18px;
      color: ${({ theme }) => theme.color_grey__600};
      margin-bottom: 15px;
    }

    img {
      margin: 0 0 15px 0;
    }

    ul {
      padding: 0;
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        color: ${({ theme }) => theme.color_grey__600};

        .text {
          margin-left: 5px;

          &.disabled {
            color: ${({ theme }) => theme.color_grey__500};
          }
        }
      }
    }

    .arrow-right,
    .upload {
      display: flex;
      align-self: flex-end;
    }
  }
`;

export const InfoTooltipWrapper = styled.div`
  box-shadow: 0px 2px 4px #5060704d;
  border-radius: 6px;
  padding: 20px;
  .publication:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color_grey__300};
    padding-bottom: 10px;
  }
  .publication:not(:first-child) {
    padding-top: 10px;
  }
  .publication {
    .publisher,
    .date {
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
      span:last-child {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          display: block;
        }
      }
    }
    .bg-grey {
      color: ${({ theme }) => theme.color_grey__400};
    }
  }
`;

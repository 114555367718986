import { Panel } from 'primereact/panel';
import DataTable from '../../../../../../Components/DataTable/DataTable';
import { useRagConfig } from '../../../../../../Contexts/RagConfigContext';
import { useTranslation } from 'react-i18next';
import { PrimeIcons } from 'primereact/api';
import { IconTrigger } from '../../../../../ConfigurationsRag/Style';
import Icon from '../../../../../../Components/Icon/Icon';
import { deleteRagConfigurationSource, downloadRagConfigurationFile } from '../../../../../../Tools/Api';
import { useState } from 'react';
import { toastError, toastSuccess } from '../../../../../../Tools/Toast';
import { useUserContext } from '../../../../../../Contexts/UserContext';
import Button from '../../../../../../Components/Button/Button';
import ModalFilesUpload from './ModalFilesUpload';
import addFile from '../../../../../../Assets/Images/addFile.png';
import { EmptyFilesWrapper } from '../Style';
import ModalDelete from '../../../../../ConfigurationsRag/Components/ModalDelete';
import { Tag } from 'primereact/tag';
import { StatusProps } from '../Display';
import { Skeleton } from 'primereact/skeleton';

interface FileListProps extends StatusProps {
  sourcesStatus: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusFileDTO[] | undefined;
}

const FileList = ({
  sourcesStatus,
  getSourceStatusBySourceId,
  statusLabelMap,
  getStatusSeverity,
  getStatusOfSource,
  isStatusLoading,
}: FileListProps) => {
  const { selectedRagConfiguration, setSelectedRagConfiguration } = useRagConfig();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string | undefined>('');
  const { t } = useTranslation();
  const { casClient, encodedNextUrl } = useUserContext();
  const token = casClient.getToken();
  const filesContent: API_BACKEND.Schemas.RAGSourceFileDTO[] | undefined =
    selectedRagConfiguration?.content?.sources?.files?.map((file) => {
      return file;
    });

  const fileColumns = [
    {
      id: 'name',
      title: t('general.name'),
      style: { width: '20%' },
      body: (rowData: API_BACKEND.Schemas.RAGSourceFileDTO) => {
        return (
          <a
            href='#'
            onClick={(event) => {
              event.preventDefault();
              selectedRagConfiguration &&
                downloadRagConfigurationFile(selectedRagConfiguration?.id, rowData.id, token)
                  .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = rowData.name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  })
                  .catch((error: any) => {
                    if (error?.response?.status == '401') {
                      casClient.logout(encodedNextUrl);
                    }
                    toastError(t('config_rag.uploadFile.downloadError'));
                  });
            }}
          >
            {rowData.name}
          </a>
        );
      },
    },
    {
      id: 'addedBy',
      title: t('general.addedBy'),
      style: { width: '20%' },
    },
    {
      id: 'addedAt',
      title: t('general.createdAt'),
      style: { width: '20%' },
      body: (rowData: API_BACKEND.Schemas.RAGSourceFileDTO) => {
        if (rowData.updatedAt) {
          const date = new Date(rowData.updatedAt);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          return `${day}/${month}/${year} ${t('general.at')} ${hours}:${minutes}`;
        }
        return '';
      },
    },
    {
      id: 'status',
      title: t('general.status'),
      body: (rowData: API_BACKEND.Schemas.RAGSourceFileDTO) => {
        const status = getStatusOfSource(getSourceStatusBySourceId(sourcesStatus, rowData.id));
        return (
          <>
            {isStatusLoading ? (
              <Skeleton height='2rem' width='10rem' borderRadius='7px' />
            ) : (
              <Tag
                value={statusLabelMap[status]}
                severity={getStatusSeverity(status)}
                pt={{
                  root: { style: { maxWidth: '100%', fontSize: '10px' } },
                }}
              />
            )}
          </>
        );
      },
    },
    {
      id: 'actions',
      title: t('general.action'),
      style: { width: '20%' },
      body: (rowData: API_BACKEND.Schemas.RAGSourceFileDTO) => {
        return (
          <IconTrigger
            onClick={(event) => {
              event.stopPropagation();
              setIdToDelete(rowData.id);
              setVisibleDelete(true);
            }}
          >
            <Icon color='red' icon='trash' size={20} />
          </IconTrigger>
        );
      },
    },
  ];

  const handleDeleteFile = () => {
    selectedRagConfiguration &&
      deleteRagConfigurationSource(selectedRagConfiguration?.id, idToDelete, token)
        .then(() => {
          setSelectedRagConfiguration &&
            setSelectedRagConfiguration({
              ...selectedRagConfiguration,
              content: {
                ...selectedRagConfiguration.content,
                sources: {
                  ...selectedRagConfiguration.content?.sources,
                  files: selectedRagConfiguration.content?.sources?.files?.filter((file) => {
                    return file.id !== idToDelete;
                  }),
                },
              },
            });
        })
        .then(() => {
          toastSuccess(t('config_rag.uploadFile.deleteSuccess'));
        })
        .catch((error: any) => {
          if (error?.response?.status == '401') {
            casClient.logout(encodedNextUrl);
          }
          toastError(t('config_rag.uploadFile.deleteError'));
        });
  };
  const renderFilePanelHeader = () => {
    return (
      <div className={'uploadFile-header'}>
        <div className='title'>{t('config_rag.uploadFile.title')}</div>
        <Button
          label={t('config_rag.uploadFile.addNewFile')}
          icon='pi pi-plus'
          onClick={() => {
            setVisible(true);
          }}
        />
        <ModalFilesUpload visible={visible} onHide={() => setVisible(false)} setVisible={setVisible} />
      </div>
    );
  };
  const isFilesInConfiguration =
    selectedRagConfiguration?.content?.sources?.files?.length &&
    selectedRagConfiguration?.content?.sources?.files?.length > 0;
  return isFilesInConfiguration ? (
    <>
      <Panel
        header={renderFilePanelHeader()}
        toggleable
        collapsed={false}
        key={'filesRag'}
        expandIcon={PrimeIcons.CHEVRON_DOWN}
        collapseIcon={PrimeIcons.CHEVRON_UP}
        pt={{
          header: { style: { display: 'flex', flexDirection: 'row-reverse', gap: '20px' } },
        }}
      >
        <DataTable data={filesContent} columns={fileColumns} rowClassName={null} rows={5} />
      </Panel>
      <ModalDelete
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onHide={() => setVisibleDelete(false)}
        handleDelete={handleDeleteFile}
      />
    </>
  ) : (
    <EmptyFilesWrapper>
      <div className='inner-content' onClick={() => setVisible(true)}>
        <img src={addFile} alt={'addFile-logo'} width={'30%'} />
        <div className='title-subtitle-content'>
          <h1 className='title'>{t('config_rag.uploadFile.titleEmpty')}</h1>
          <h2 className='subtitle'>{t('config_rag.uploadFile.subtitleEmpty')}</h2>
        </div>
      </div>
      <ModalFilesUpload visible={visible} onHide={() => setVisible(false)} setVisible={setVisible} />
    </EmptyFilesWrapper>
  );
};

export default FileList;

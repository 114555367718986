import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

export const toastSuccess = (message?: any) => {
  toast.success(message || 'Operation successfully completed', {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const toastWarn = (message?: any) => {
  toast.warn(message || 'Warning !', {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const toastInfo = (message?: any) => {
  toast.info(message || 'Update completed !', {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const toastNoContent = (message?: any) => {
  toast.warn(message || 'No content to read', {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const toastError = (error?: any) => {
  if (error?.response?.status === 401) {
    console.log('Error 401');
  } else {
    const text =
      typeof error === 'string'
        ? error || 'Operation failed, please try again'
        : error?.response?.data?.message || error?.message;
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

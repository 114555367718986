import { MainLabel } from 'src/Components/Style';
import styled from 'styled-components';

export const ActionContainer = styled.div`
  margin: 10px 10px 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled(MainLabel)`
  display: flex;
  flex-grow: 0;
  align-items: center;
  min-width: 275px;
  .pi {
    margin: 2px 10px 0 0;
    font-size: 16px;
  }
  .image {
    max-width: 20px;
    max-height: 20px;
    height: auto !important;
    margin: 0 10px 0 0;

    &.p-skeleton {
      min-height: 20px;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .title {
    font-size: 16px;
    padding: 0 40px 0 0;
  }
  .subtitle {
    font-size: 14px;
    padding: 0 40px 0 0;
  }
`;

export const ActionContent = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: end;
  flex-direction: row;

  .p-tag {
    cursor: ${(onClick) => onClick && 'pointer'};
  }
`;

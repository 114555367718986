import Icon from '../../../../../../../Components/Icon/Icon';

export interface MeterGroupValue {
  label: string;
  color: string;
  value: number;
}

export const formatBoolean = (value: boolean | undefined) => {
  return <>{value ? <Icon icon='check' color='green' /> : <Icon icon='close' color='red' />}</>;
};

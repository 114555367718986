import ActionWrapper from 'src/Components/ActionWrapper/ActionWrapper';
import Chip from 'src/Components/Chip/Chip';
import { Divider } from 'src/Components/Divider/Style';
import { InformationsProps } from '../Informations';
import { InnerContent } from 'src/Pages/Edition/Style';
import { getCurrentBot } from 'src/Redux/Bot/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { toastSuccess } from '../../../../../../../Tools/Toast';

interface GeneralProps extends InformationsProps {}

const General = ({ watch }: GeneralProps) => {
  const { t } = useTranslation();

  const currentBot = useSelector(getCurrentBot);
  return (
    <InnerContent>
      <ActionWrapper title={t('edition.generalInfo.field.bot')} body={<Chip label={currentBot?.name} />} />
      <Divider />
      <ActionWrapper title={t('edition.generalInfo.field.server')} body={<Chip label={watch('server.hostname')} />} />
      <Divider />
      <ActionWrapper
        title={t('edition.generalInfo.field.endpoint')}
        body={
          <>
            <Button
              label={`${watch('endpoint')}`}
              className='p-button-secondary p-button-text'
              style={{ fontSize: '14px' }}
              onClick={() => {
                navigator.clipboard
                  .writeText(watch('endpoint'))
                  .then(() => toastSuccess(t('general.copy_to_clipboard.success')));
              }}
            >
              <i className='pi pi-copy ml-2' style={{ fontSize: '1.3em' }}></i>
            </Button>
          </>
        }
      />
    </InnerContent>
  );
};

export default General;

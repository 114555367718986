import styled, { css } from 'styled-components';

import { Message as PrimeMessage } from 'primereact/message';

interface MessageWrapperProps {
  onClick?: () => void;
  wide?: boolean;
  hidden?: boolean;
}

export const MessageWrapper = styled.div`
  ${({ hidden }: MessageWrapperProps) =>
    hidden &&
    css`
      display: none;
    `}
  .p-inline-message {
    align-items: baseline !important;

    .p-inline-message-text {
      font-size: 14px;
    }

    .p-inline-message-icon {
      font-size: 14px;
      margin-right: 10px;
    }
  }

  ${({ onClick }: MessageWrapperProps) =>
    onClick &&
    css`
      cursor: pointer;
    `}
  ${({ wide }: MessageWrapperProps) =>
    wide &&
    css`
      .p-inline-message {
        width: 100%;
        justify-content: flex-start;
      }
    `}
`;

export const Message = styled(PrimeMessage)``;

import { Dialog } from 'primereact/dialog';
import Button from '../../../Components/Button/Button';
import { useTranslation } from 'react-i18next';
import { HeaderWrapper, ModalWrapper } from '../Style';

interface ModalCreateRagProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onHide: () => void;
  handleDelete: () => void;
}

const ModalDelete = ({ visible, setVisible, onHide, handleDelete }: ModalCreateRagProps) => {
  const { t } = useTranslation();

  const deleteAndClose = () => {
    handleDelete();
    setVisible(false);
  };
  const renderheaderModal = (
    <HeaderWrapper>
      <h1>{t('config_rag.modalDelete.title')}</h1>
    </HeaderWrapper>
  );

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      dismissableMask={true}
      style={{ width: '30vw' }}
      header={renderheaderModal}
    >
      <ModalWrapper>
        <h2>{t('config_rag.modalDelete.subtitle')}</h2>
        <div className={'actions-modal'}>
          <Button label={t('config_rag.modalDelete.delete')} onClick={deleteAndClose} className={'delete-button'} />
        </div>
      </ModalWrapper>
    </Dialog>
  );
};

export default ModalDelete;

import content from './content.json';
import contentPatchToNextVersion from './content-patch-to-next-version.json';
import contentSchema from './content-schema.json';
import { Release } from '../../../../../typings/states';
import nextRelease from '../chatbox_edge_2024-05-14/chatbox_edge_2024-05-14';

const release: Release = {
  version: 'chatbox_edge_2024-01-09',
  content: content,
  contentSchema: contentSchema,
  contentPatchToNextVersion: contentPatchToNextVersion,
  nextRelease: nextRelease,
};

export default release;

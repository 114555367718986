import { getSelectedBot, getUserHost, getUserId, getUserLang, getUserName } from 'src/Redux/User/selectors';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useDydu = () => {
  const userName = useSelector(getUserName);
  const userId = useSelector(getUserId);
  const userLang = useSelector(getUserLang);
  const host = useSelector(getUserHost);
  const selectedBot = useSelector(getSelectedBot);

  const isDyduUser = useMemo((): boolean | undefined => userName?.includes('@dydu.ai'), [userName]);

  const pattern = /^(dev\.[a-zA-Z]+|alpha|echo)\.doyoudreamup\.com$/;

  const isDevelopmentServer = useMemo((): boolean | undefined => pattern.test(host), [host]);

  return { userName, userId, userLang, host, selectedBot, isDyduUser, isDevelopmentServer };
};

export default useDydu;

import ActionWrapper from 'src/Components/ActionWrapper/ActionWrapper';
import Button from 'src/Components/Button/Button';
import { Divider } from 'src/Components/Divider/Style';
import Heading from 'src/Components/Heading/Heading';
import { InnerContent } from 'src/Pages/Edition/Style';
import Menu from 'src/Components/Menu/Menu';
import Message from 'src/Components/Message/Message';
import { ActionsButtonsContainer, PublishPanel } from '../Style';
import { copyToClipboard } from 'src/Tools/Text';
import { useTranslation } from 'react-i18next';
import RestoreButton from '../../../../../../Components/RestoreButton/RestoreButton';
import { CONFIG_TYPES } from '../../../../../../Tools/Constants';
import ControlledSwitch from '../../../../../../Components/Switch/ControlledSwitch';
import ControlledInput from '../../../../../../Components/Input/ControlledInput';
import { Control, UseFormWatch } from 'react-hook-form';

interface PanelProps {
  heading: string;
  content?: {
    title: string;
    body?: any;
  };
  disabled?: boolean;
  className?: string;
  url?: string;
  version?: string;
  env: string;
  hasUpdate?: boolean;
  handleDownloadZip?: (env) => void;
  configId?: string;
  publications?: API_BACKEND.Schemas.ConfigurationPublicationDTO[];
  configType: API_BACKEND.Schemas.ConfigurationType | undefined;
  watch: UseFormWatch<any>;
  control: Control<any>;
}

const Panel = ({
  heading,
  content,
  disabled,
  url,
  version,
  env,
  handleDownloadZip,
  hasUpdate,
  className = '',
  configId,
  publications,
  configType,
  control,
  watch,
}: PanelProps) => {
  const { t } = useTranslation();

  const scriptSrc = `<script src="${url}bundle.min.js"></script>`;

  const handleMailTo = () => {
    const formattedBody = `${scriptSrc}`;
    const mailToLink = `mailto:contact@email.com?body=${t(
      'edition.publish.action.mailtoBody'
    )}%20%20%20${encodeURIComponent(formattedBody)}`;
    window.location.href = mailToLink;
  };

  const menuItems = [
    {
      items: [
        {
          label: 'Wordpress',
          icon: 'icon-link',
          url: 'https://docs.dydu.ai/integrations/canaux/customisation-avancee/integrations-possibles#wordpress',
          target: '_blank',
        },
        {
          label: 'Service Now',
          icon: 'icon-link',
          url: 'https://docs.dydu.ai/integrations/canaux/customisation-avancee/integrations-possibles#servicenow',
          target: '_blank',
        },
      ],
    },
  ];

  const bundleActions = [
    {
      icon: 'icon-eye',
      onClick: () => window.open(url, '_blank'),
      tooltip: t('edition.publish.action.seeChatbot'),
    },
    {
      icon: 'icon-download',
      onClick: () => handleDownloadZip && handleDownloadZip(env),
      tooltip: t('edition.publish.action.downloadZip', { env }),
    },
    {
      icon: 'icon-copy',
      onClick: () => url && copyToClipboard(scriptSrc),
      tooltip: t('edition.publish.action.copy'),
    },
    { icon: 'icon-send', onClick: handleMailTo, tooltip: t('edition.publish.action.mailto') },
  ];

  const renderPublicationState = () => {
    if (disabled) {
      return t('edition.publish.action.disabled');
    }
    return !url && !version
      ? t('edition.publish.action.enable')
      : `${t('edition.publish.action.published')} ${
          version ? t('edition.publish.action.publishedVersion', { version }) : ''
        }`;
  };

  const renderActions = () => (
    <ActionsButtonsContainer>
      {bundleActions.map((action) => (
        <Button
          key={action.icon}
          tooltipOptions={{ mouseTrack: true }}
          {...action}
          className='p-button-text p-button-secondary'
          icon={action.icon}
        />
      ))}
      {configId && publications && configType !== CONFIG_TYPES.CUSTOMER ? (
        <RestoreButton
          label={t('edition.publish.action.restore')}
          iconPos='right'
          icon='icon-history'
          configId={configId}
          env={env}
          publications={publications?.filter((publication) => publication.type === env)}
        />
      ) : null}
    </ActionsButtonsContainer>
  );

  const renderBundleMenu = () => (
    <Menu
      model={menuItems}
      buttonIcon='icon-caret-down'
      buttonClassName='p-button-text p-button-sm inegration-menu'
      buttonLabel={t('edition.publish.action.howtoint')}
      buttonIconPos='right'
      popup
    />
  );

  const renderBundle = () =>
    url && (
      <>
        <Divider />
        <ActionWrapper title={t('edition.publish.script')} body={renderBundleMenu()} />
        <div className='bundle'>
          <div className='url'>{scriptSrc}</div>
          <div className='actions'>{renderActions()}</div>
        </div>
      </>
    );

  const isEnable = watch('servletUrls.' + env.toLowerCase() + '.enable') === true;

  const renderUrlsPart = () => (
    <>
      <Divider />
      <ControlledSwitch
        control={control}
        label={t('edition.publish.action.servletUrls.title')}
        icon='link'
        name={'servletUrls.' + env.toLowerCase() + '.enable'}
        defaultValue={isEnable}
      />
      <ControlledInput
        className='servletUrls'
        wide
        control={control}
        placeholder={t('edition.publish.action.servletUrls.mainPlaceholder')}
        name={'servletUrls.' + env.toLowerCase() + '.mainUrl'}
        defaultValue={watch('servletUrls.' + env.toLowerCase() + '.mainUrl')}
        title={t('edition.publish.action.servletUrls.mainIntro')}
        hidden={!isEnable}
      />
      <ControlledInput
        wide
        control={control}
        placeholder={t('edition.publish.action.servletUrls.backupPlaceholder')}
        name={'servletUrls.' + env.toLowerCase() + '.backupUrls'}
        defaultValue={watch('servletUrls.' + env.toLowerCase() + '.backupUrls')}
        title={t('edition.publish.action.servletUrls.backupIntro')}
        hidden={!isEnable}
      />
      <Message wide severity='info' text={t('edition.publish.action.servletUrls.message')} hidden={!isEnable} />
    </>
  );

  const renderStateMessage = () => {
    if (hasUpdate) {
      return <Message wide severity='warn' text={t('edition.publish.action.hasUpdate')} />;
    }
  };

  const renderContent = () => content && <ActionWrapper title={content?.title} body={content?.body} />;

  return (
    <PublishPanel className={`publish-panel ${className}`}>
      <InnerContent className={`panel ${url ? 'published' : ''} ${hasUpdate ? 'hasUpdate' : ''}`} disabled={disabled}>
        <Heading as='h4' title={heading} action={renderPublicationState()} />
        {renderStateMessage()}
        {renderContent()}
        {renderUrlsPart()}
        {renderBundle()}
      </InnerContent>
    </PublishPanel>
  );
};

export default Panel;

import content from './content.json';
import contentPatchToNextVersion from './content-patch-to-next-version.json';
import contentSchema from './content-schema.json';
import translation from './translation.json';
import translationPatchToNextVersion from './translation-patch-to-next-version.json';
import translationSchema from './translation-schema.json';
import { Release } from '../../../../../typings/states';
import nextRelease from '../chatbox_edge_2024-10-29/chatbox_edge_2024-10-29';

const release: Release = {
  version: 'chatbox_edge_2024-10-15',
  content: content,
  contentSchema: contentSchema,
  contentPatchToNextVersion: contentPatchToNextVersion,
  translation: translation,
  translationSchema: translationSchema,
  translationPatchToNextVersion: translationPatchToNextVersion,
  nextRelease: nextRelease,
};

export default release;

import { useTranslation } from 'react-i18next';
import { useCallBotConfigInfo } from '../../../../../Contexts/CallBotContext';
import { Card } from 'primereact/card';
import { CallBotCardWrapper, CallBotCarouselWrapper } from '../../../Style';
import { Carousel } from 'primereact/carousel';
import Image from '../../../../../Components/Image/Image';
import AudioPlayer from './AudioPlayer';

interface CallBotConfigCardProps {
  imageByTts: (ttsProvider: string) => string;
  imageByStt: (sttProvider: string) => string;
  isProviderHaveImage: (provider) => boolean;
}

const CallBotConfigCard = ({ imageByTts, imageByStt, isProviderHaveImage }: CallBotConfigCardProps) => {
  const { selectedCallBotConfig, voicesListForSelectedInstance } = useCallBotConfigInfo();
  const { t } = useTranslation();

  const getNameForVoiceSelected = () => {
    return voicesListForSelectedInstance.find((voiceItem) => voiceItem.id === selectedCallBotConfig.tts?.name)?.name;
  };
  const providerTemplate = (provider) => (
    <CallBotCarouselWrapper>
      <p>{provider}</p>
      <div className='stt-wrapper'>
        <Image src={imageByStt(provider)} width={60} />
      </div>
    </CallBotCarouselWrapper>
  );

  const renderTtsProvider = (provider) => (
    <div>
      <h1>{t('edition.callbot.provider')}</h1>
      {isProviderHaveImage(provider) ? (
        <Image src={imageByTts(selectedCallBotConfig.tts?.provider)} width={60} />
      ) : (
        <p>{provider}</p>
      )}
    </div>
  );
  return (
    <CallBotCardWrapper>
      <Card title={t('edition.callbot.phone')} className='callbot-phone'>
        <div>
          <p>{(selectedCallBotConfig.phoneLine.match(/.{1,2}/g) || []).join(' ')}</p>
        </div>
      </Card>
      <Card title={t('edition.callbot.TTS')} className='callbot-tts-config'>
        <div className='tts-card'>
          <div>{renderTtsProvider(selectedCallBotConfig.tts?.provider)}</div>
          <div>
            <h1>{t('edition.callbot.language')}</h1>
            <Image src={`Icons/flags/${selectedCallBotConfig.language}.png`} width={40} />
          </div>
          <div>
            <h1>{t('edition.callbot.chosenVoice')}</h1>
            <p>{getNameForVoiceSelected()}</p>
            <AudioPlayer
              content={t('edition.callbot.voiceWelcome')}
              provider={selectedCallBotConfig.tts?.provider}
              instanceUuid={selectedCallBotConfig.uuid}
              voice={selectedCallBotConfig.tts?.name}
              language={selectedCallBotConfig.language}
            />
          </div>
        </div>
      </Card>
      <Card title={t('edition.callbot.STT')} className='callbot-stt'>
        <Carousel
          value={selectedCallBotConfig.stt?.enabledModels}
          numVisible={1}
          numScroll={1}
          itemTemplate={providerTemplate}
          showIndicators={false}
        />
      </Card>
    </CallBotCardWrapper>
  );
};

export default CallBotConfigCard;

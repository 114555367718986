import { AVATAR_TYPE, UPLOAD_TYPE } from 'src/Tools/Constants';

import ControlledInput from 'src/Components/Input/ControlledInput';
import ControlledSwitch from 'src/Components/Switch/ControlledSwitch';
import { DisplayProps } from '../Display';
import { Divider } from 'src/Components/Divider/Style';
import ImageUpload from 'src/Components/ImageUpload/ImageUpload';
import { InnerContent } from 'src/Pages/Edition/Style';
import { SubField } from 'src/Components/Style';
import { useTranslation } from 'react-i18next';

interface DialogProps extends DisplayProps {}

const Dialog = ({ control, watch, imageUpload, imagesData }: DialogProps) => {
  const { t } = useTranslation();

  const imageList = [
    {
      title: t('edition.display.images.understood'),
      src: imagesData?.understood,
      type: AVATAR_TYPE.UNDERSTOOD,
      field: 'imagesData.understood',
    },
    {
      title: t('edition.display.images.misunderstood'),
      src: imagesData?.misunderstood,
      type: AVATAR_TYPE.MISUNDERSTOOD,
      field: 'imagesData.misunderstood',
    },
    {
      title: t('edition.display.images.reword'),
      src: imagesData?.reword,
      type: AVATAR_TYPE.REWORD,
      field: 'imagesData.reword',
    },
  ];

  const handleImageUpload = (file: File, type: string, field: string) => imageUpload(file, type, field);

  const renderImagesUpload = () =>
    imageList.map((image, index) => (
      <div key={image.type}>
        <ImageUpload
          key={image.type}
          src={image.src}
          title={image.title}
          size='large'
          onUpload={(file) => handleImageUpload(file, image.type, image.field)}
          accept={UPLOAD_TYPE.IMAGE}
        />
        {index !== imageList.length - 1 && <Divider />}
      </div>
    ));

  const renderImageLivechatUpload = () => (
    <div key={AVATAR_TYPE.LIVECHAT}>
      <ImageUpload
        key={AVATAR_TYPE.LIVECHAT}
        src={imagesData?.livechat}
        title={t('edition.display.images.livechat')}
        size='large'
        onUpload={(file) => handleImageUpload(file, AVATAR_TYPE.LIVECHAT, 'imagesData.livechat')}
        accept={UPLOAD_TYPE.IMAGE}
      />
    </div>
  );

  return (
    <InnerContent className='dialog'>
      <ControlledSwitch
        control={control}
        label={t('edition.display.field.avatarResponse')}
        icon='profile'
        name='content.avatar.response.enable'
      />
      <Divider />
      <ControlledSwitch
        control={control}
        label={t('edition.display.field.displayNameBot')}
        icon='signature'
        name='content.interaction.displayNameBot'
      />
      <SubField hidden={watch && !watch('content.interaction.displayNameBot')}>
        <ControlledInput
          wide
          control={control}
          label={t('edition.display.field.NameBot')}
          delay={500}
          name='content.interaction.NameBot'
        />
      </SubField>
      <Divider />
      <ControlledSwitch
        control={control}
        icon='masks'
        label={t('edition.display.title.customAvatar')}
        name='content.header.logo.customAvatar'
      />
      <SubField hidden={watch && !watch('content.header.logo.customAvatar')}>
        <div className='bot-reactions'>{renderImagesUpload()}</div>
      </SubField>
      <Divider />
      <ControlledSwitch
        control={control}
        icon='headphones'
        label={t('edition.display.title.livechatCustomAvatar')}
        name='content.header.livechatLogo.livechatCustomAvatar'
      />
      <SubField hidden={watch && !watch('content.header.livechatLogo.livechatCustomAvatar')}>
        <div className='livechat-logo'>{renderImageLivechatUpload()}</div>
      </SubField>
    </InnerContent>
  );
};

export default Dialog;

export function getLastPublicationOfType(
  publications: API_BACKEND.Schemas.ConfigurationPublicationDTO[] | null | undefined,
  type: string
): API_BACKEND.Schemas.ConfigurationPublicationDTO | null {
  if (publications) {
    const filteredPublications: API_BACKEND.Schemas.ConfigurationPublicationDTO[] = publications.filter(
      (e) => e.type?.toUpperCase() === type.toUpperCase()
    );

    if (filteredPublications?.length > 0) {
      return filteredPublications.reduce((prev, curr) =>
        !prev?.publishedAt || !curr?.publishedAt || prev?.publishedAt < curr?.publishedAt ? curr : prev
      );
    }
  }

  return null;
}

export function getCdnServerUrl(configuration: API_BACKEND.Schemas.DyduboxConfigurationDTO, envType: string) {
  const urlCdn = getLastPublicationOfType(configuration.publications, envType)?.urlCdn;
  let cdnServerUrl;
  if (urlCdn) {
    cdnServerUrl = `${urlCdn.replace('/dydubox', '/cdn-server/dydubox')}`;
  }
  return `${cdnServerUrl}`;
}

export function hasBeenPublishedIn(
  publications: API_BACKEND.Schemas.ConfigurationPublicationDTO[] | null | undefined,
  type: string
): boolean {
  if (publications) {
    return publications.some((e) => e.type?.toUpperCase() === type.toUpperCase());
  }

  return false;
}

import { APP_ENV, CHATBOX_VERSION } from 'src/Tools/Constants';
import { ColumnWrapper, EditionWrapper } from 'src/Pages/Edition/Style';

import Heading from 'src/Components/Heading/Heading';
import Panel from './Publish/Panel';
import PublishButton from 'src/Components/PublishButton/PublishButton';
import { WebsiteStepProps } from 'src/Pages/Edition/Containers/Website/WebsiteContainer';
import { useTranslation } from 'react-i18next';
import { getLastPublicationOfType } from '../../../../../Tools/DyduboxConfigurationUtils';

export interface AdvancedProps extends WebsiteStepProps {}

const Publish = ({ watch, control, deploy, downloadZip, selectedConfig }: AdvancedProps) => {
  const { t } = useTranslation();

  const publications = watch('publications');

  const lastPreprodPublication = getLastPublicationOfType(publications, 'PREPROD');
  const lastProdPublication = getLastPublicationOfType(publications, 'PROD');

  const isEmpty = (obj): boolean => {
    return obj === undefined || obj === null || obj.toString().replace(/\s/g, '') === '';
  };

  return (
    <EditionWrapper>
      {/** Publish */}
      <Heading as='h4' title={t('edition.publish.title')} />
      <ColumnWrapper>
        {/** PREPROD */}
        <Panel
          heading={t('edition.publish.preprod.heading')}
          content={{
            title: t('edition.publish.preprod.content.title'),
            body: (
              <PublishButton
                label={t('edition.publish.action.publish')}
                iconPos='right'
                icon='icon-branche'
                env={APP_ENV.PREPROD}
                id={watch('id')}
                onClick={(version) => deploy(APP_ENV.PREPROD, version)}
                configType={selectedConfig.type}
                watch={watch}
              />
            ),
          }}
          handleDownloadZip={downloadZip}
          env={APP_ENV.PREPROD}
          url={lastPreprodPublication?.urlCdn}
          version={lastPreprodPublication?.version}
          hasUpdate={!isEmpty(lastPreprodPublication?.version) && lastPreprodPublication?.version !== CHATBOX_VERSION}
          configType={selectedConfig.type}
          watch={watch}
          control={control}
        />

        {/** PROD */}
        <Panel
          heading={t('edition.publish.prod.heading')}
          disabled={!lastPreprodPublication && !lastProdPublication}
          content={{
            title: lastPreprodPublication
              ? t('edition.publish.prod.content.title.enable')
              : t('edition.publish.prod.content.title.disable'),
            body: (
              <PublishButton
                label={t('edition.publish.action.publish')}
                iconPos='right'
                icon='icon-branche'
                env={APP_ENV.PROD}
                id={watch('id')}
                onClick={(version) => deploy(APP_ENV.PROD, version)}
                configType={selectedConfig.type}
                watch={watch}
              />
            ),
          }}
          handleDownloadZip={downloadZip}
          env={APP_ENV.PROD}
          url={lastProdPublication?.urlCdn}
          version={lastProdPublication?.version}
          hasUpdate={!isEmpty(lastProdPublication?.version) && lastProdPublication?.version !== CHATBOX_VERSION}
          configId={watch('id')}
          publications={watch('publications')}
          configType={selectedConfig.type}
          control={control}
          watch={watch}
        />
      </ColumnWrapper>
    </EditionWrapper>
  );
};

export default Publish;

import { Panel } from 'primereact/panel';
import { PrimeIcons } from 'primereact/api';
import DataTable from '../../../../../../Components/DataTable/DataTable';
import ModalDelete from '../../../../../ConfigurationsRag/Components/ModalDelete';
import { useRagConfig } from '../../../../../../Contexts/RagConfigContext';
import {
  addRagConfigurationWebSite,
  deleteRagConfigurationSource,
  updateRagConfigurationWebsite,
} from '../../../../../../Tools/Api';
import { toastError, toastSuccess } from '../../../../../../Tools/Toast';
import { IconTrigger } from '../../../../../ConfigurationsRag/Style';
import Icon from '../../../../../../Components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EmptyUrlWrapper } from '../Style';
import addUrl from '../../../../../../Assets/Images/url.png';
import { useUserContext } from '../../../../../../Contexts/UserContext';
import ModalWebsites from './ModalWebsites';
import Button from '../../../../../../Components/Button/Button';
import { Tag } from 'primereact/tag';
import { StatusProps } from '../Display';
import { Skeleton } from 'primereact/skeleton';
import ModalErrorStatusInformationWebsite from './ErrorStatusInformation/ModalErrorStatusInformationWebsite';
import { UrlType } from './WebsiteUrlType';

interface WebSitesListProps extends StatusProps {
  sourcesStatus: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusFileDTO[] | undefined;
}

const WebSitesList = ({
  sourcesStatus,
  statusLabelMap,
  getStatusSeverity,
  getStatusOfSource,
  getSourceStatusBySourceId,
  isStatusLoading,
}: WebSitesListProps) => {
  const { t } = useTranslation();
  const { selectedRagConfiguration, setSelectedRagConfiguration } = useRagConfig();
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [currentWebSite, setCurrentWebSite] = useState<any>(null);
  const [idToDelete, setIdToDelete] = useState<string | undefined>('');
  const [isErrorStatusModalVisible, setIsErrorStatusModalVisible] = useState<boolean>(false);
  const [selectedSourceStatus, setSelectedSourceStatus] = useState<
    API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusWebsiteDTO | undefined
  >(undefined);
  const { casClient, encodedNextUrl } = useUserContext();
  const token = casClient.getToken();
  const urlSources = selectedRagConfiguration?.content?.sources?.websites?.map((url) => {
    return url;
  });
  const urlColumns = [
    {
      id: 'name',
      title: t('general.name'),
      style: { width: '20%' },
      body: (rowData: any) => {
        return <>{rowData.name}</>;
      },
    },
    {
      id: 'addedBy',
      title: t('general.addedBy'),
      style: { width: '20%' },
    },
    {
      id: 'addedAt',
      title: t('general.createdAt'),
      style: { width: '20%' },
      body: (rowData: API_BACKEND.Schemas.RAGSourceWebsiteDTO) => {
        if (rowData.updatedAt) {
          const date = new Date(rowData.updatedAt);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          return `${day}/${month}/${year} ${t('general.at')} ${hours}:${minutes}`;
        }
        return '';
      },
    },
    {
      id: 'status',
      title: t('general.status'),
      body: (rowData: API_BACKEND.Schemas.RAGSourceWebsiteDTO) => {
        const sourceStatus = getSourceStatusBySourceId(sourcesStatus, rowData.id);
        const status = getStatusOfSource(sourceStatus);
        return (
          <>
            {isStatusLoading ? (
              <Skeleton width='5rem' borderRadius='16px' className='mb-2' />
            ) : (
              <>
                <Tag
                  value={statusLabelMap[status]}
                  severity={getStatusSeverity(status)}
                  title={
                    status === 'COMPLETED' || status === 'COMPLETED_WITH_ERRORS'
                      ? t('config_rag.general.status.sources.report.statusHover')
                      : ''
                  }
                  className={status === 'COMPLETED' || status === 'COMPLETED_WITH_ERRORS' ? 'cursor-pointer' : ''}
                  onClick={() => {
                    if (status === 'COMPLETED' || status === 'COMPLETED_WITH_ERRORS') {
                      setSelectedSourceStatus(sourceStatus);
                      setIsErrorStatusModalVisible(true);
                    }
                  }}
                  pt={{
                    root: { style: { maxWidth: '100%', fontSize: '10px' } },
                  }}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      id: 'actions',
      title: t('general.actions'),
      style: { width: '20%' },
      body: (rowData: API_BACKEND.Schemas.RAGSourceWebsiteDTO) => {
        return (
          <div className='actions-list'>
            <IconTrigger
              onClick={(event) => {
                event.stopPropagation();
                setIdToDelete(rowData.id);
                setVisibleDelete(true);
              }}
            >
              <Icon color='red' icon='trash' />
            </IconTrigger>
            <IconTrigger
              onClick={(event) => {
                event.stopPropagation();
                setCurrentWebSite(rowData);
                setVisible(true);
              }}
            >
              <Icon color='black' icon='pencil' />
            </IconTrigger>
          </div>
        );
      },
    },
  ];

  const changeCurrentWebsiteValues = (name: string, type: UrlType, urls: string[], url: string): Promise<void> => {
    const websiteUrls: string[] = type === UrlType.SpecificUrl ? urls : [];
    const sitemapUrl: string = type === UrlType.SitemapUrl ? url : '';
    const domainToCrawl: string = type === UrlType.DomainToCrawl ? url : '';

    const newWebsite = {
      ...currentWebSite,
      name: name,
      urls: websiteUrls,
      domainToCrawl: domainToCrawl,
      sitemapUrl: sitemapUrl,
    };

    setCurrentWebSite(newWebsite);

    if (newWebsite?.id) {
      return handleUpdateWebsite(newWebsite);
    } else {
      return handleAddWebsite(newWebsite);
    }
  };

  const handleAddWebsite = async (data): Promise<void> => {
    if (selectedRagConfiguration) {
      addRagConfigurationWebSite(selectedRagConfiguration?.id, data, token)
        .then((res: API_BACKEND.Schemas.RAGSourceWebsiteDTO) => {
          const newRagConfig: API_BACKEND.Schemas.RAGConfigurationDTO = {
            ...selectedRagConfiguration,
          };
          newRagConfig?.content?.sources?.websites?.push(res);
          return newRagConfig;
        })
        .then((newRagConfig: API_BACKEND.Schemas.RAGConfigurationDTO) => {
          toastSuccess(t('config_rag.websites.addSuccess'));
          setSelectedRagConfiguration && setSelectedRagConfiguration(newRagConfig);
          setVisible(false);
        })
        .catch((error: any) => {
          if (error?.response?.status == '401') {
            casClient.logout(encodedNextUrl);
          }
          toastError(t('config_rag.websites.addError'));
        });
    } else {
      return Promise.resolve();
    }
  };

  const handleUpdateWebsite = (data): Promise<void> => {
    if (selectedRagConfiguration) {
      return updateRagConfigurationWebsite(selectedRagConfiguration?.id, data, token)
        .then((res: API_BACKEND.Schemas.RAGSourceWebsiteDTO) => {
          const newRagConfig: API_BACKEND.Schemas.RAGConfigurationDTO = {
            ...selectedRagConfiguration,
          };

          if (newRagConfig?.content?.sources) {
            newRagConfig.content.sources.websites = newRagConfig.content?.sources?.websites?.map((website) => {
              return website.id === res.id ? res : website;
            });
          }

          return newRagConfig;
        })
        .then((newRagConfig: API_BACKEND.Schemas.RAGConfigurationDTO) => {
          toastSuccess(t('config_rag.websites.updateSuccess'));
          setSelectedRagConfiguration && setSelectedRagConfiguration(newRagConfig);
          setVisible(false);
        })
        .catch((error: any) => {
          if (error?.response?.status == '401') {
            casClient.logout(encodedNextUrl);
          }
          toastError(t('config_rag.websites.updateError'));
        });
    } else {
      return Promise.resolve();
    }
  };

  const handleDeleteWebsite = () => {
    selectedRagConfiguration &&
      deleteRagConfigurationSource(selectedRagConfiguration?.id, idToDelete || '', token)
        .then(() => {
          setSelectedRagConfiguration &&
            setSelectedRagConfiguration({
              ...selectedRagConfiguration,
              content: {
                ...selectedRagConfiguration.content,
                sources: {
                  ...selectedRagConfiguration.content?.sources,
                  websites: selectedRagConfiguration.content?.sources?.websites?.filter((file) => {
                    return file.id !== idToDelete;
                  }),
                },
              },
            });
          toastSuccess(t('config_rag.websites.deleteSuccess'));
        })
        .catch((error: any) => {
          if (error?.response?.status == '401') {
            casClient.logout(encodedNextUrl);
          }
          toastError(t('config_rag.websites.deleteError'));
        });
  };

  const renderUrlListHeader = () => {
    return (
      <div className='website-header'>
        <h1>{t('config_rag.websites.title')}</h1>
        <Button
          label={t('config_rag.websites.addWebSite')}
          icon='pi pi-plus'
          onClick={() => {
            setCurrentWebSite(null);
            setVisible(true);
          }}
        />
        <ModalWebsites
          visible={visible}
          onHide={() => setVisible(false)}
          changeCurrentWebsiteValues={changeCurrentWebsiteValues}
          currentWebSite={currentWebSite}
        />
      </div>
    );
  };
  const isWebSitesInConfiguration =
    selectedRagConfiguration?.content?.sources?.websites?.length &&
    selectedRagConfiguration?.content?.sources?.websites?.length > 0;

  return isWebSitesInConfiguration ? (
    <>
      <Panel
        toggleable
        collapsed={false}
        key={'webSitesRag'}
        expandIcon={PrimeIcons.CHEVRON_DOWN}
        collapseIcon={PrimeIcons.CHEVRON_UP}
        header={renderUrlListHeader()}
        pt={{
          header: { style: { display: 'flex', flexDirection: 'row-reverse', gap: '20px' } },
        }}
      >
        <DataTable data={urlSources} columns={urlColumns} rowClassName={null} rows={5} />
        <ModalDelete
          visible={visibleDelete}
          setVisible={setVisibleDelete}
          onHide={() => setVisibleDelete(false)}
          handleDelete={handleDeleteWebsite}
        />
      </Panel>
      <ModalErrorStatusInformationWebsite
        visible={isErrorStatusModalVisible}
        onHide={() => setIsErrorStatusModalVisible(false)}
        sourceStatus={selectedSourceStatus}
      />
    </>
  ) : (
    <EmptyUrlWrapper>
      <div
        className='inner-content'
        onClick={() => {
          setCurrentWebSite(null), setVisible(true);
        }}
      >
        <img src={addUrl} alt={'sharepoint-logo'} width={'30%'} />
        <div className='title-subtitle-content'>
          <h1 className='title'>{t('config_rag.websites.titleEmpty')}</h1>
          <h2 className='subtitle'>{t('config_rag.websites.subtitleEmpty')}</h2>
        </div>
      </div>
      <ModalWebsites
        visible={visible}
        onHide={() => setVisible(false)}
        changeCurrentWebsiteValues={changeCurrentWebsiteValues}
        currentWebSite={currentWebSite}
      />
    </EmptyUrlWrapper>
  );
};

export default WebSitesList;

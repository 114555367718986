import { MessageWrapper, Message as PrimeMessage } from './Style';

interface MessageProps {
  text: string | null;
  className?: string;
  wide?: boolean;
  severity: 'success' | 'info' | 'warn' | 'error';
  onClick?: () => void;
  hidden?: boolean;
}

const Message = ({ text, severity, onClick, className = '', wide, hidden }: MessageProps) =>
  text && typeof text === 'string' ? (
    <MessageWrapper wide={wide} hidden={hidden} className={`message ${severity} ${className}`} onClick={onClick}>
      <PrimeMessage className='error-message' severity={severity} text={text} />
    </MessageWrapper>
  ) : (
    <></>
  );

export default Message;

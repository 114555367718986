import styled from 'styled-components';

export const StepperWrapper = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -10px 10px #152d620d;
  background-color: ${({ theme }) => theme.color_white};

  .inner-content {
    display: flex;
    flex-direction: row;

    &:first-child {
      Button {
        margin-right: 15px;
      }
    }
  }

  .step {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 50px;
    font-size: 16px;

    .number {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      border: 1px solid ${({ theme }) => theme.color_grey__400};
      margin-right: 10px;
      text-align: center;
      color: ${({ theme }) => theme.color_grey__400};
      transition: all ease 0.5s;
      position: relative;
    }

    .label {
      font-size: 14px;
      color: ${({ theme }) => theme.color_grey__400};
      transition: all ease 0.5s;
    }

    &.active {
      .label {
        color: #41479b;
      }

      .number {
        color: #41479b;
        border-color: #41479b;
      }
    }

    &.done {
      .number {
        background-color: ${({ theme }) => theme.color_grey__400};

        &::after {
          display: block;
          content: 'L';
          position: absolute;
          right: 1px;
          top: 1px;
          width: 10px;
          height: 10px;
          color: white;
          font-family: arial;
          font-size: 16px;
          transform: scaleX(-1) rotate(-46deg);
        }
      }
    }
  }
`;

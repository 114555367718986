import { Dialog } from 'primereact/dialog';
import Input from '../../../../../../Components/Input/Input';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../../Components/Button/Button';
import { ModalHeaderSharePoint, ModalSharePoint, SiteIds } from '../Style';
import Icon from '../../../../../../Components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import { Label } from '../../../../../../Components/Input/Style';
import { toastError } from '../../../../../../Tools/Toast';
import { APP_URL } from 'src/Tools/Constants';
import LocalStorage from 'src/Tools/LocalStorage';

interface ModalCreateSharepointProps {
  visible: boolean;
  onHide: () => void;
  handleAddSharepoint: (data) => void;
  handleUpdateSharepoint: (data) => void;
  currentSharepoint: API_BACKEND.Schemas.RAGSourceSharepointDTO | undefined | null;
}

const ModalSharepoint = ({
  visible,
  onHide,
  handleAddSharepoint,
  handleUpdateSharepoint,
  currentSharepoint,
}: ModalCreateSharepointProps) => {
  const initialFormState: API_BACKEND.Schemas.RAGSourceSharepointDTO = {
    name: '',
    clientId: '',
    clientSecret: '',
    tenantId: '',
    siteIds: [],
  };
  const [formData, setFormData] = useState<API_BACKEND.Schemas.RAGSourceSharepointDTO>(initialFormState);
  const { t } = useTranslation();
  const [newSiteId, setNewSiteId] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const guideUrl = APP_URL ? APP_URL[LocalStorage.locale.load()].ragSharepointGuide : '';

  useEffect(() => {
    if (currentSharepoint) {
      setFormData({
        name: currentSharepoint.name,
        clientId: currentSharepoint.clientId,
        clientSecret: currentSharepoint.clientSecret,
        tenantId: currentSharepoint.tenantId,
        siteIds: currentSharepoint?.siteIds,
      });
    } else {
      setFormData(initialFormState);
      setSubmitted(false);
    }
  }, [currentSharepoint, visible]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { siteIds, ...restFormData } = formData;
  const formDataWithoutSiteIds = restFormData;

  const labelNameForEachKey = {
    name: 'general.name',
    clientId: 'config_rag.sharepoint.clientID',
    clientSecret: 'config_rag.sharepoint.clientSecret',
    tenantId: 'config_rag.sharepoint.tenantID',
  };

  const data = {
    id: currentSharepoint?.id || '',
    ...formData,
  };

  const handleSiteIdsChange = (newSiteIds) => {
    setFormData({ ...formData, siteIds: newSiteIds });
  };

  const titleAddOrUpdate = currentSharepoint
    ? 'config_rag.sharepoint.modalTitleEdit'
    : 'config_rag.sharepoint.modalTitleAdd';

  const renderHeaderModalSharePoint = () => {
    return (
      <>
        <h2>{t(titleAddOrUpdate)}</h2>
        <ModalHeaderSharePoint href={guideUrl} target='_blank'>
          <div className='title-sharepoint-modal'>{t('config_rag.sharepoint.modalDocumentation')}</div>
          <div className='subtitle-sharepoint-modal'>{t('config_rag.sharepoint.modalSubtitle')}</div>
          <div className='caret-sharepoint-modal'>
            <Icon icon='caret-right' color='white' />
          </div>
        </ModalHeaderSharePoint>
      </>
    );
  };

  const handleSiteIdChange = (value) => {
    setNewSiteId(value);
  };

  const handleAddSiteId = () => {
    handleSiteIdsChange([...(siteIds || []), newSiteId]);
    setNewSiteId('');
  };

  const initValue = siteIds && siteIds.length > 0 ? siteIds : [];

  const isValid = newSiteId !== '';

  const isUpdate = !!currentSharepoint;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const isFormValid = Object.values(formData).every((value) => value.length > 0);

  const checkFormBeforeSubmit = () => {
    setSubmitted(true);
    if (isFormValid) {
      if (isUpdate) {
        handleUpdateSharepoint(data);
      } else {
        handleAddSharepoint(data);
      }
    } else {
      toastError(t('config_rag.sharepoint.formInvalid'));
    }
  };

  const renderSiteIdsInput = () => {
    return (
      <SiteIds>
        <Label className='label-siteid-input'>{t('config_rag.sharepoint.siteID')}</Label>
        <div className='wrapper-siteid-select'>
          <Input
            value={newSiteId}
            onChange={(e) => handleSiteIdChange(e.target.value)}
            placeholder={t('site ID')}
            className={'input-siteid'}
          />
          <Button
            label={t('general.add')}
            className={'button-add-siteid'}
            icon='icon-add'
            onClick={handleAddSiteId}
            disabled={!isValid}
          />
          <MultiSelect
            value={initValue}
            invalid={submitted && siteIds?.length === 0}
            className={'multi-select-siteid'}
            options={siteIds}
            display='comma'
            onChange={(e) => handleSiteIdsChange([...e.value])}
            emptyMessage={t('config_rag.sharepoint.emptyMultiSelect')}
            checkboxIcon='pi pi-times'
            placeholder={t('site ID')}
            maxSelectedLabels={1}
            selectedItemsLabel={siteIds?.length + ' ' + t('general.selected')}
            pt={{
              checkbox: {
                box: {
                  style: {
                    border: 'none',
                    backgroundColor: '#edf1f5',
                    cursor: 'pointer',
                    pointerEvents: 'initial',
                  },
                },
                icon: {
                  style: { color: 'red', height: '16px', background: '#edf1f5' },
                },
              },
              item: { style: { cursor: 'default', pointerEvents: 'none' } },
            }}
          />
        </div>
      </SiteIds>
    );
  };

  return (
    <Dialog visible={visible} onHide={onHide} dismissableMask={true} header={renderHeaderModalSharePoint}>
      <ModalSharePoint>
        {Object.keys(formDataWithoutSiteIds).map((key, index) => (
          <Input
            key={index}
            value={formDataWithoutSiteIds[key as keyof typeof formDataWithoutSiteIds]}
            onChange={(e) => handleChange(e, key)}
            placeholder={key}
            label={t(labelNameForEachKey[key as keyof typeof labelNameForEachKey])}
            maxLength={50}
            invalid={submitted && formDataWithoutSiteIds[key as keyof typeof formDataWithoutSiteIds]?.length === 0}
          />
        ))}
        {renderSiteIdsInput()}
        <div className='button-container'>
          <Button
            label={t(isUpdate ? 'general.update' : 'general.create')}
            onClick={checkFormBeforeSubmit}
            icon='icon-check'
          />
        </div>
      </ModalSharePoint>
    </Dialog>
  );
};
export default ModalSharepoint;

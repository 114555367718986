import ConsultationSpaces from './Advanced/ConsultationSpaces';
import { EditionWrapper } from 'src/Pages/Edition/Style';
import Heading from 'src/Components/Heading/Heading';
import Integration from './Advanced/Integration';
import Knowledge from './Advanced/Knowledge';
import Privacy from './Advanced/Privacy';
import Survey from './Advanced/Survey';
import Translation from './Advanced/Translation';
import { WebsiteStepProps } from 'src/Pages/Edition/Containers/Website/WebsiteContainer';
import WithPreview from 'src/HOC/WithPreview/withPreview';
import { useTranslation } from 'react-i18next';

export interface AdvancedProps extends WebsiteStepProps {}

const Advanced = (props: AdvancedProps) => {
  const { t } = useTranslation();

  return (
    <EditionWrapper className='with-preview'>
      {/** Knowledge */}
      <Heading as='h4' title={t('edition.display.title.knowledge')} />
      <Knowledge {...props} />

      {/** Consultation Spaces */}
      <Heading as='h4' title={t('edition.display.title.consultationSpaces')} />
      <ConsultationSpaces {...props} />

      {/** Survey */}
      <Heading as='h4' title={t('edition.display.title.survey')} />
      <Survey {...props} />

      {/** Integration */}
      <Heading as='h4' title={t('edition.display.title.integration')} />
      <Integration {...props} />

      {/** Translation */}
      <Heading as='h4' title={t('edition.display.title.translation')} />
      <Translation {...props} />

      {/** Privacy */}
      <Heading as='h4' title={t('edition.display.title.privacy')} />
      <Privacy {...props} />
    </EditionWrapper>
  );
};

export default WithPreview(Advanced);

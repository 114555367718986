import { useTranslation } from 'react-i18next';
import ImageGoogle from '../../../../../Assets/Images/logo-google.png';
import ImageMicrosoft from '../../../../../Assets/Images/logo-microsoft.png';
import ImageDydu from '../../../../../Assets/Images/logo-dydu.png';
import ImageVoxygen from '../../../../../Assets/Images/logo-voxygen.png';
import ImageElevenLabs from '../../../../../Assets/Images/logo-elevenlabs.png';
import ImageAcapela from '../../../../../Assets/Images/logo-acapela.png';
import { CallBotWrapper } from '../../../Style';
import { Panel } from 'primereact/panel';
import { PrimeIcons } from 'primereact/api';
import CallBotConfigCard from '../../../Containers/CallBot/Components/CallBotConfigCard';
import TtsPanel from '../../../Containers/CallBot/Components/TtsPanel';

const Display = () => {
  const { t } = useTranslation();

  const imageByStt = (sttProvider: string) => {
    switch (sttProvider) {
      case 'GOOGLE':
        return ImageGoogle;
      case 'MICROSOFT':
        return ImageMicrosoft;
      case 'DYDU_WHISPER':
        return ImageDydu;
      default:
        return '';
    }
  };

  const providerLogoList = [
    { label: 'GOOGLE', value: ImageGoogle },
    { label: 'MICROSOFT', value: ImageMicrosoft },
    { label: 'DYDU_WHISPER', value: ImageDydu },
    { label: 'VOXYGEN', value: ImageVoxygen },
    { label: 'ELEVENLABS', value: ImageElevenLabs },
    { label: 'ACAPELA', value: ImageAcapela },
  ];

  const isProviderHaveImage = (provider: string) => {
    return providerLogoList.some((providerLogo) => providerLogo.label === provider);
  };

  const imageByTts = (ttsProvider: string) => {
    const isTtsProviderExist = providerLogoList.some((provider) => provider.label === ttsProvider);
    if (!isTtsProviderExist) return ttsProvider;
    return providerLogoList.find((provider) => provider.label === ttsProvider)?.value;
  };

  return (
    <CallBotWrapper>
      <Panel
        header={t('edition.callbot.actualConfig')}
        toggleable
        key={'configCard'}
        expandIcon={PrimeIcons.CHEVRON_DOWN}
        collapseIcon={PrimeIcons.CHEVRON_UP}
      >
        <CallBotConfigCard imageByTts={imageByTts} imageByStt={imageByStt} isProviderHaveImage={isProviderHaveImage} />
      </Panel>
      <Panel
        header={t('edition.callbot.TTS')}
        toggleable
        key={'tts'}
        expandIcon={PrimeIcons.CHEVRON_DOWN}
        collapseIcon={PrimeIcons.CHEVRON_UP}
      >
        <TtsPanel imageByTts={imageByTts} isProviderHaveImage={isProviderHaveImage} />
      </Panel>
    </CallBotWrapper>
  );
};

export default Display;

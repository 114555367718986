import { APP_PATH } from 'src/Tools/Constants';

import { getMessageError } from 'src/Redux/Configurations/selectors';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Actions from 'src/Components/Actions/Actions';
import { ErrorWrapper } from '../Style';
import Message from 'src/Components/Message/Message';
import { useTranslation } from 'react-i18next';
import { useRagConfig } from '../../../Contexts/RagConfigContext';
import Display from '../Components/Forms/Rag/Display';
import { useUserContext } from '../../../Contexts/UserContext';
import useDocumentTitle from '../../../Hooks/useDocumentTitle';

const EditionRagContainer = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const messageError = useSelector(getMessageError);
  const { selectedRagConfiguration, ragConfigs, setSelectedRagConfiguration } = useRagConfig();
  const { casClient, encodedNextUrl } = useUserContext();

  useDocumentTitle(
    `${t('general.pages.rag')} - ${t('general.pages.ragConfigurations')} - ${selectedRagConfiguration?.name}`
  );

  useEffect(() => {
    const selectedConfig = ragConfigs && ragConfigs?.find((config: any) => config.id === id);
    const selectedConfigWithSources = selectedConfig && {
      ...selectedConfig,
      content: {
        ...selectedConfig.content,
        sources: {
          files: selectedConfig.content?.sources?.files || [],
          sharepoints: selectedConfig.content?.sources?.sharepoints || [],
          websites: selectedConfig.content?.sources?.websites || [],
        },
      },
    };
    if (setSelectedRagConfiguration && selectedConfigWithSources)
      setSelectedRagConfiguration(selectedConfigWithSources);
  }, [ragConfigs]);

  const renderContent = () => {
    if (selectedRagConfiguration) {
      return <Display />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (messageError?.endsWith('401')) {
      casClient.logout(encodedNextUrl);
    }
  }, [messageError]);

  const renderErrorMessage = () =>
    messageError &&
    !messageError.endsWith('401') && (
      <ErrorWrapper>
        <Message severity='error' text={messageError} />
        <Actions
          actions={[
            {
              label: t('general.ragEdition'),
              icon: 'icon-arrow-left',
              onClick: () => navigate(APP_PATH.CONFIG_RAG),
            },
          ]}
        />
      </ErrorWrapper>
    );

  return (
    <div className='edition-container'>
      {renderContent()}
      {renderErrorMessage()}
    </div>
  );
};

export default EditionRagContainer;

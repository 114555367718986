import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { HeaderWrapper } from '../../../../../../ConfigurationsRag/Style';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MeterGroup } from 'primereact/metergroup';
import { TreeTable } from 'primereact/treetable';
import { TreeNode } from 'primereact/treenode';
import { MultilineColumnData } from './Style';
import { formatBoolean, MeterGroupValue } from './ModalErrorStatusInformation';

interface ModalErrorStatusInformationWebsiteProps {
  visible: boolean;
  onHide: () => void;
  sourceStatus?: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusWebsiteDTO;
}

const ModalErrorStatusInformationWebsite = ({
  visible,
  onHide,
  sourceStatus,
}: ModalErrorStatusInformationWebsiteProps) => {
  const { t } = useTranslation();

  const renderHeaderModal = (
    <HeaderWrapper>
      <h1 className='p-panel-title'>
        {sourceStatus?.isExtractionSuccessful
          ? t('config_rag.general.status.sources.report.modal.title')
          : t('config_rag.general.status.sources.report.modal.errorTitle')}
      </h1>
    </HeaderWrapper>
  );

  const getHttpCodeColor = (httpCode: number) => {
    const firstChar = httpCode.toString()[0];
    switch (firstChar) {
      case '1':
        return '#1abc9c';
      case '2':
        return '#239b56';
      case '3':
        return '#76448a';
      case '4':
        return '#d4ac0d';
      case '5':
        return '#a93226';
      default:
        return '#1abc9c';
    }
  };

  const formatHttpCodeMeterGroup = (
    values: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusWebsiteOtherDTO | undefined
  ) => {
    const data: MeterGroupValue[] = [];
    values?.urlsPerCode
      ?.sort((a, b) => a.code - b.code)
      .forEach((urlPerCode) => {
        if (urlPerCode) {
          const firstNumberCode = urlPerCode.code.toString()[0];
          const label = t(`general.httpCodes.${firstNumberCode}xx`);
          const meterGroupValue: MeterGroupValue | undefined = data.filter(
            (tn: MeterGroupValue) => tn.label === label
          )[0];
          if (!meterGroupValue) {
            data.push({
              label: label,
              color: getHttpCodeColor(urlPerCode.code),
              value: urlPerCode.count,
            });
          } else {
            meterGroupValue.value += urlPerCode.count;
          }
        }
      });
    return data;
  };

  const getTotalUrlCount = (
    values: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusWebsiteOtherDTO | undefined
  ) => {
    let total = 0;
    values?.urlsPerCode?.forEach((urlPerCode) => (total += urlPerCode.count));
    return total;
  };

  const formatTreeTableValues = (
    values: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusWebsiteOtherDTO | undefined
  ) => {
    const data: TreeNode[] = [];
    values?.urlsPerCode?.forEach((urlPerCode) => {
      if (urlPerCode) {
        const firstNumberCode = urlPerCode.code.toString()[0];
        let rootTreeNode: TreeNode | undefined = data.filter((tn) => tn.label?.toString()[0] === firstNumberCode)[0];
        if (!rootTreeNode) {
          const label = t(`general.httpCodes.${firstNumberCode}xx`);
          rootTreeNode = {
            id: firstNumberCode,
            key: firstNumberCode,
            label: `${firstNumberCode}xx`,
            leaf: false,
            data: {
              code: `${firstNumberCode}xx`,
              codeLabel: label,
              count: countHttpCodeType(firstNumberCode, values.urlsPerCode),
            },
            children: [],
          };
          data.push(rootTreeNode);
        }

        if (!rootTreeNode.children) {
          rootTreeNode.children = [];
        }

        rootTreeNode.children.push({
          id: urlPerCode.code.toString(),
          key: urlPerCode.code.toString(),
          label: `${urlPerCode.code}`,
          leaf: true,
          data: {
            count: urlPerCode.count,
            code: urlPerCode.code,
            codeLabel: urlPerCode.code,
            urls: (
              <MultilineColumnData>
                {urlPerCode.urls?.map((url: string, index: number) => (
                  <span key={index}>{url}</span>
                ))}
              </MultilineColumnData>
            ),
          },
        });
      }
    });
    return data;
  };

  const countHttpCodeType = (
    rootCode: string,
    values: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusWebsiteOtherCodeDTO[] | undefined
  ) => {
    let count = 0;
    values?.forEach((urlPerCode) => {
      const firstNumberCode = urlPerCode.code.toString()[0];
      if (rootCode === firstNumberCode) {
        count += urlPerCode.count;
      }
    });
    return count;
  };

  const renderYoutubePart = (
    youtubes: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusWebsiteYoutubeDTO[] | undefined
  ) => {
    if (youtubes && youtubes.length > 0) {
      return (
        <>
          <p className='mb-1'>{t('config_rag.general.status.sources.report.modal.website.youtube.name')}</p>
          <DataTable
            value={youtubes}
            tableStyle={{ minWidth: '50rem' }}
            paginator
            rows={5}
            alwaysShowPaginator={false}
            showGridlines={true}
          >
            <Column field='url' header={t('config_rag.general.status.sources.report.modal.website.url')}></Column>
            <Column
              field='isVideoReachable'
              header={t('config_rag.general.status.sources.report.modal.website.youtube.isVideoReachable')}
              align={'center'}
              alignHeader={'center'}
              body={(value) => formatBoolean(value.isVideoReachable)}
            ></Column>
            <Column
              field='isTranscriptAvailable'
              header={t('config_rag.general.status.sources.report.modal.website.youtube.isTranscriptAvailable')}
              align={'center'}
              alignHeader={'center'}
              body={(value) => formatBoolean(value.isTranscriptAvailable)}
            ></Column>
          </DataTable>
        </>
      );
    }

    return null;
  };

  const renderOthersPart = (
    others: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusWebsiteOtherDTO | undefined,
    youtubes: API_BACKEND.Schemas.RAGConfigurationProcessSourceStatusWebsiteYoutubeDTO[] | undefined
  ) => {
    const hasYoutube = youtubes && youtubes?.length > 0;
    if (others) {
      return (
        <>
          <p className={hasYoutube ? 'mb-1 mt-5' : 'mb-1'}>
            {t('config_rag.general.status.sources.report.modal.website.name')}
          </p>
          <div className='card'>
            <MeterGroup
              values={formatHttpCodeMeterGroup(others)}
              max={getTotalUrlCount(others)}
              pt={{
                labellist: {
                  style: {
                    marginTop: '5px',
                  },
                },
              }}
            />
            <br />
            <TreeTable
              sortField={'code'}
              sortOrder={1}
              value={formatTreeTableValues(others)}
              tableStyle={{ minWidth: '50rem', maxHeight: '500px' }}
              scrollable
              scrollHeight='flex'
              showGridlines={true}
            >
              <Column
                field={'codeLabel'}
                header={t('config_rag.general.status.sources.report.modal.website.httpCode')}
                expander
                sortable={true}
              />
              <Column field={'count'} header={t('config_rag.general.status.sources.report.modal.website.count')} />
              <Column field={'urls'} header={t('config_rag.general.status.sources.report.modal.website.urls')} />
            </TreeTable>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      dismissableMask={true}
      header={renderHeaderModal}
      draggable={false}
      style={{ width: '700px' }}
    >
      {sourceStatus &&
      ((sourceStatus.youtubes && sourceStatus.youtubes.length > 0) ||
        (sourceStatus.others && sourceStatus.others.urlsPerCode && sourceStatus.others?.urlsPerCode.length > 0)) ? (
        <>
          {renderYoutubePart(sourceStatus.youtubes)}
          {renderOthersPart(sourceStatus.others, sourceStatus.youtubes)}
        </>
      ) : (
        <>{t('config_rag.general.status.sources.report.modal.noData')}</>
      )}
    </Dialog>
  );
};

export default ModalErrorStatusInformationWebsite;

import { formInterface, stepsInterface } from '../EditionContainer';
import Display from '../../Components/Forms/Callbot/Display';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CallBotContainer = (props: formInterface) => {
  const { currentStep, setStepperConfig } = props;
  const { t } = useTranslation();
  // Add future steps here to display them in the stepper
  // need to use selectedCallBotConfig from context to save changes in the callbot config during the edition.
  const steps: stepsInterface[] = [
    {
      label: t('edition.display.title.view'),
      body: <Display />,
    },
  ];

  useEffect(() => {
    setStepperConfig(steps);
  }, []);

  return <div className='website-container'>{steps[currentStep]?.body}</div>;
};

export default CallBotContainer;

import { APP_PATH, CONFIG_TYPES } from 'src/Tools/Constants';

import CreateConfigContainer from 'src/Modals/CreateConfig/CreateConfigContainer';
import DotActions from 'src/Components/DotActions/DotActions';
import UpdateNameContainer from 'src/Modals/UpdateName/UpdateNameContainer';
import UpdateSlugContainer from 'src/Modals/UpdateSlug/UpdateSlugContainer';
import { WebsiteActionsProps } from '../Containers/WebsiteActionsContainer';
import useDydu from 'src/Hooks/useDydu';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../../Contexts/UserContext';

const RowActions = ({ config, openModal, hasVersionUpdate, deleteModalActions }: WebsiteActionsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDyduUser } = useDydu();
  const { setCurrentNavigationStep } = useUserContext();

  const dotActions = [
    {
      title: t('configurations.dot_actions.replace'),
      onClick: () =>
        openModal(
          t('configurations.modal.replace', { name: config?.name }),
          <CreateConfigContainer forcedMode='replace' config={config} type={config?.type} />
        ),
    },
    {
      title: t('configurations.dot_actions.edit_config'),
      onClick: () => navigate(`${APP_PATH.CONFIGURATIONS}/${config.type}/${config.id}`),
    },
    {
      title: t('configurations.dot_actions.edit_name'),
      onClick: () =>
        openModal(t('configurations.title_modal_update_config_name'), <UpdateNameContainer config={config} />),
    },
    {
      title: t('configurations.dot_actions.edit_slug'),
      visible: isDyduUser,
      onClick: () =>
        openModal(t('configurations.title_modal_update_config_slug'), <UpdateSlugContainer config={config} />),
    },
    {
      title: t('configurations.dot_actions.publish_update'),
      visible: config.type === CONFIG_TYPES.WEBSITE,
      onClick: () => {
        navigate(`${APP_PATH.CONFIGURATIONS}/${config.type}/${config.id}`);
        setCurrentNavigationStep && setCurrentNavigationStep(2);
      },
    },
    {
      title: t('configurations.dot_actions.delete'),
      type: 'error',
      onClick: () => openModal(`${t('general.delete')} ${config.name}`, t('delete_config.text'), deleteModalActions),
    },
  ];

  return <DotActions actions={dotActions} hasNotification={hasVersionUpdate} />;
};

export default RowActions;

import { useTranslation } from 'react-i18next';
import { useCallBotConfigInfo } from '../../../../../Contexts/CallBotContext';
import DataTable from '../../../../../Components/DataTable/DataTable';
import Image from '../../../../../Components/Image/Image';
import { MultiSelect } from 'primereact/multiselect';
import { useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { TtsPanelWrapper } from '../../../Style';
import Input from '../../../../../Components/Input/Input';
import SelectNewVoice from './SelectNewVoice';
import AudioPlayer from './AudioPlayer';

interface TtsPanelProps {
  imageByTts: (ttsProvider: string) => string;
  isProviderHaveImage?: (provider) => boolean;
}

const TtsPanel = ({ imageByTts, isProviderHaveImage }: TtsPanelProps) => {
  const { selectedCallBotConfig, voicesListForSelectedInstance } = useCallBotConfigInfo();
  const { t } = useTranslation();
  const defaultText = t('edition.callbot.defaultText');
  const [textToSpeech, setTextToSpeech] = useState(defaultText);
  const [filters] = useState({
    provider: { value: null, matchMode: FilterMatchMode.CONTAINS },
    language: { value: [selectedCallBotConfig.language], matchMode: FilterMatchMode.CONTAINS },
    gender: { value: null, matchMode: FilterMatchMode.IN },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const providersList = voicesListForSelectedInstance
    .map((voice) => {
      return { label: voice.provider, value: voice.provider };
    })
    .filter((provider, index, self) => self.findIndex((t) => t.value === provider.value) === index);

  const languageList = voicesListForSelectedInstance
    .map((voice) => {
      return { label: voice.language, value: voice.language };
    })
    .filter((language, index, self) => {
      if (language.value === undefined) return false;
      return self.findIndex((t) => t.value === language.value) === index;
    });

  const genderList = voicesListForSelectedInstance
    .map((voice) => {
      return { label: voice.gender, value: voice.gender };
    })
    .filter((gender, index, self) => {
      if (gender.value === undefined) return false;
      return self.findIndex((t) => t.value === gender.value) === index;
    });

  const handleChangeValue = (e, options) => {
    const newValue = e.value.length ? [e.value[e.value.length - 1]] : [];
    options.filterApplyCallback(newValue);
  };

  const rowFilterTemplate = (options) => {
    const list =
      options.field === 'provider' ? providersList : options.field === 'language' ? languageList : genderList;
    return (
      <MultiSelect
        value={options.value}
        options={list}
        showSelectAll={false}
        onChange={(e) => handleChangeValue(e, options)}
        optionLabel='label'
        placeholder={t('edition.callbot.placeHolderListTts')}
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{ minWidth: '12rem' }}
      />
    );
  };

  const columns = [
    {
      id: 'provider',
      title: t('general.provider'),
      field: 'provider',
      style: { width: '20%' },
      filterPlaceholder: t('general.search'),
      filter: true,
      showFilterMenu: false,
      filterElement: rowFilterTemplate,
      body: (rowData) => {
        if (isProviderHaveImage && isProviderHaveImage(rowData.provider)) {
          return <Image src={imageByTts(rowData.provider)} width={50} />;
        } else {
          return <p>{rowData.provider}</p>;
        }
      },
    },
    {
      id: 'language',
      title: t('general.language'),
      field: 'language',
      style: { width: '15%' },
      filter: true,
      filterPlaceholder: t('general.search'),
      filterElement: rowFilterTemplate,
      showFilterMenu: false,
      body: (rowData) => {
        return <Image src={`Icons/flags/${rowData?.language}.png`} width={24} />;
      },
    },
    {
      id: 'gender',
      title: t('general.gender'),
      field: 'gender',
      style: { width: '20%' },
      filter: true,
      filterPlaceholder: t('general.search'),
      filterElement: rowFilterTemplate,
      showFilterMenu: false,
    },
    {
      id: 'name',
      title: t('general.name'),
      filter: true,
      filterPlaceholder: t('general.search'),
      field: 'name',
      showFilterMenu: false,
      body: (rowData) => {
        return (
          <div className='name-column'>
            <div className='test-audio-column'>
              <p>{rowData.name}</p>
              <AudioPlayer
                content={textToSpeech}
                provider={rowData.provider}
                instanceUuid={selectedCallBotConfig.uuid}
                voice={rowData.id}
                language={rowData.language}
              />
            </div>
            <SelectNewVoice voice={rowData} />
          </div>
        );
      },
    },
  ];
  return (
    <TtsPanelWrapper>
      <div className='tts-header'>
        <div>
          <p>{t('edition.callbot.testVoices')}</p>
        </div>
        <div>
          <Input
            type={'text'}
            value={textToSpeech}
            onChange={(e) => setTextToSpeech(e.target.value)}
            maxLength={200}
            placeholder={t('edition.callbot.placeHolderTestVoice')}
          />
        </div>
      </div>
      <DataTable
        rowClassName={null}
        data={voicesListForSelectedInstance}
        columns={columns}
        filterDisplay='row'
        filters={filters}
        rows={10}
        rowHover={true}
      />
    </TtsPanelWrapper>
  );
};

export default TtsPanel;

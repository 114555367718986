import ActionWrapper from 'src/Components/ActionWrapper/ActionWrapper';
import { DisplayProps } from '../Display';
import { Divider } from 'src/Components/Divider/Style';
import { InnerContent } from 'src/Pages/Edition/Style';
import { TRANSLATION } from 'src/Redux/Configurations/types';
import TranslationDownload from 'src/Components/TranslationDownload/TranslationDownload';
import TranslationUpload from 'src/Components/TranslationUpload/TranslationUpload';
import { UPLOAD_TYPE } from 'src/Tools/Constants';
import { getLoadingStatus } from 'src/Redux/Loading/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface TranslationProps extends DisplayProps {}

const Translation = ({ uploadTranslationExcel, downloadTranslation }: TranslationProps) => {
  const { t } = useTranslation();
  const uploadExcelLoading = useSelector((state) => getLoadingStatus(state, TRANSLATION.UPLOAD_EXCEL.REQUEST));
  const downloadLoading = useSelector((state) => getLoadingStatus(state, TRANSLATION.DOWNLOAD.REQUEST));

  return (
    <InnerContent className='translation'>
      <ActionWrapper
        icon='excel'
        title={t('edition.advanced.field.translation.download.label')}
        body={
          <TranslationDownload
            className='p-button-outlined'
            onClick={downloadTranslation}
            label={t('edition.advanced.field.translation.download.button')}
            loading={downloadLoading}
          />
        }
      />
      <Divider />
      <ActionWrapper
        icon='translate'
        title={t('edition.advanced.field.translation.upload.label')}
        body={
          <TranslationUpload
            label={t('edition.advanced.field.translation.upload.button')}
            onUpload={uploadTranslationExcel}
            accept={UPLOAD_TYPE.XLSX}
            loading={uploadExcelLoading}
          />
        }
      />
    </InnerContent>
  );
};

export default Translation;

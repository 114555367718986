import * as Constants from 'src/Tools/Constants';
import * as Types from './types';

import LocalStorage from 'src/Tools/LocalStorage';
import { action } from 'src/Tools/Redux';

/**
 * REDUCER ACTIONS
 */

export const setCurrentBot: Redux.ActionFactory = (data) => {
  LocalStorage.setItem(Constants.DyduStorage.CURRENT_BOT, JSON.stringify(data));
  sessionStorage.removeItem(Constants.ATRIA_BOTUUID);
  return action(Types.SET_CURRENT_BOT, { data });
};

/**
 * SAGAS ACTIONS
 */

export const getBots = {
  request: (userId: string) => action(Types.BOT.GET_ALL.REQUEST, { userId }),
  success: (botsList) => action(Types.BOT.GET_ALL.SUCCESS, { botsList }),
  failure: (error) => action(Types.BOT.GET_ALL.FAILURE, { error }),
};

export const getConsultationSpaces = {
  request: (botUUID: string, defaultLanguage: string) =>
    action(Types.BOT.CONSULTATION_SPACES.REQUEST, { botUUID, defaultLanguage }),
  success: (data) => action(Types.BOT.CONSULTATION_SPACES.SUCCESS, { data }),
  failure: (error) => action(Types.BOT.CONSULTATION_SPACES.FAILURE, { error }),
};

export const getBotLanguages = {
  request: (botUUID: string) => action(Types.BOT.LANGUAGES.REQUEST, { botUUID }),
  success: (languages) => action(Types.BOT.LANGUAGES.SUCCESS, { languages }),
  failure: (error) => action(Types.BOT.LANGUAGES.FAILURE, { error }),
};

import styled from 'styled-components';

export const TranslationUploadWrapper = styled.div`
  input[type='file'] {
    display: none;
  }

  .p-buttonset {
    display: flex;
  }

  .p-button-label {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.3rem 0.6rem;
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-left: 5px;
  }
`;

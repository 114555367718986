import IcomoonReact from 'icomoon-react';
import { IconWrapper } from './Style';
import iconSet from './selection.json';

interface IconProps {
  color?: string;
  size?: string | number;
  icon: string;
  onClick?: (e) => void;
  className?: string;
}

const Icon = ({ className = '', color, size = 16, icon, onClick }: IconProps) => {
  const handleOnClick = (e) => onClick && onClick(e);

  return (
    <IconWrapper color={color} className={`${className} ${icon}`} onClick={onClick && handleOnClick}>
      <IcomoonReact iconSet={iconSet} color={color} size={size} icon={icon} />
    </IconWrapper>
  );
};

export default Icon;

import AdditionalTabs from './Display/AdditionalTabs';
import Appearance from './Display/Appearance';
import CssEditor from './Display/CssEditor';
import JsEditor from './Display/JsEditor';
import Debug from './Display/Debug';
import Dialog from './Display/Dialog';
import { EditionWrapper } from 'src/Pages/Edition/Style';
import { EditorToggle } from './Style';
import Heading from 'src/Components/Heading/Heading';
import Interactions from './Display/Interactions';
import Languages from './Display/Languages';
import { WebsiteStepProps } from 'src/Pages/Edition/Containers/Website/WebsiteContainer';
import WithPreview from 'src/HOC/WithPreview/withPreview';
import useDydu from 'src/Hooks/useDydu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface DisplayProps extends WebsiteStepProps {
  selectedConfig: Models.WebsiteConfiguration;
  imagesData: Models.ImagesData | null;
}

type EditorTabType = 'config' | 'css' | 'js' | 'debug';

interface EditorTabsProps {
  label: string;
  type: EditorTabType;
  disabled?: boolean;
}

const Display = (props: DisplayProps) => {
  const { t } = useTranslation();
  const { isDyduUser } = useDydu();
  const [editorTab, setEditorTab] = useState<EditorTabType>('config');

  const editorTabs: EditorTabsProps[] = [
    {
      label: t('general.standard'),
      type: 'config',
    },
    {
      label: t('general.css'),
      type: 'css',
    },
    {
      label: t('general.js'),
      type: 'js',
    },
    {
      label: t('general.debug'),
      type: 'debug',
      disabled: !isDyduUser,
    },
  ];

  const handleSetEditorVisible = (type: EditorTabType) => setEditorTab(type);

  const redenderToggle = (
    <EditorToggle>
      {editorTabs
        ?.filter((tab) => !tab.disabled)
        .map((tab) => (
          <div
            key={tab?.label}
            className={editorTab === tab?.type ? 'active' : ''}
            onClick={() => handleSetEditorVisible(tab.type)}
          >
            {tab.label}
          </div>
        ))}
    </EditorToggle>
  );

  const renderTab = () => {
    switch (editorTab) {
      case 'css':
        return <CssEditor {...props} />;
      case 'js':
        return <JsEditor {...props} />;
      case 'debug':
        return <Debug {...props} />;
      default:
        return <Appearance {...props} />;
    }
  };

  return (
    <EditionWrapper className='with-preview'>
      {/** Apparance */}
      <Heading as='h4' title={t('edition.display.title.appearance')} action={redenderToggle} />
      {renderTab()}

      {/** Dialog */}
      <Heading as='h4' title={t('edition.display.title.dialog')} />
      <Dialog {...props} />

      {/** Interactions */}
      <Heading as='h4' title={t('edition.display.title.interactions')} />
      <Interactions {...props} />

      {/** Apparance */}
      <Heading as='h4' title={t('edition.display.title.languages')} />
      <Languages {...props} />

      {/** Additional Tabs */}
      <Heading as='h4' title={t('edition.display.title.additionalTabs')} />
      <AdditionalTabs {...props} />
    </EditionWrapper>
  );
};

export default WithPreview(Display);

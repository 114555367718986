class LocalStorage {
  static names = {
    locale: 'dydu.channels.locale',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getItem = (pointer: string): any => {
    if (!pointer) {
      return false;
    }
    const item: any = localStorage.getItem(pointer);
    try {
      return JSON.parse(item);
    } catch (error: any) {
      return item;
    }
  };

  static setItem = (pointer: string, value: string) => pointer && value && localStorage.setItem(pointer, value);
  static removeItem = (pointer: string) => pointer && localStorage.removeItem(pointer);

  static locale = Object.create({
    save: (data) => localStorage.setItem(LocalStorage.names.locale, data),
    load: () => localStorage.getItem(LocalStorage.names.locale) || null,
    remove: () => localStorage.removeItem(LocalStorage.names.locale),
  });
}

export default LocalStorage;
